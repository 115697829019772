
import * as React from 'react';
import { useNotificationHolderContext } from '../../contexts/NotificationContext';
import { Stack } from '@mui/material';
import DisplayNotificationAlert from './DisplayNotificationAlert';

export interface INotificationHandlerProps {
}

const NotificationHandler: React.FunctionComponent<INotificationHandlerProps> = (props: INotificationHandlerProps) => {
    const ctx = useNotificationHolderContext()

    return (
        <>
            <Stack sx={{
                position: "fixed",
                zIndex: 100000,
                bottom: "2.5em",
                left: 0,
            }} spacing={1}
                direction={'column-reverse'}
                justifyContent="center">

                {ctx.notifications.map(n => <span key={n.id}><DisplayNotificationAlert notification={n} /></span>)}
            </Stack>
        </>
    );
}

export default NotificationHandler;