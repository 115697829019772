import { Tooltip, TooltipProps } from '@mui/material';
import * as React from 'react';

export interface IDisableTooltipProps extends Omit<TooltipProps, 'title'> {
    text?: string
    disabledText?: string
    disabled?: boolean

}

const DisableTooltip: React.FunctionComponent<React.PropsWithChildren<IDisableTooltipProps>> = (props: React.PropsWithChildren<IDisableTooltipProps>) => {
    return (
        <>
            <Tooltip {...props} title={props.disabled ? props.disabledText : props.text} >
                <span>
                    {props.children}
                </span>
            </Tooltip>
        </>
    );
}

export default DisableTooltip;