import { Chip, Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Modal, OutlinedInput, Select, SelectChangeEvent, Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { User } from '../../../clients/models/User';
import { Role } from '../../../clients/models/Role';
import { useUserApiClient } from '../../../clients/UserApiClient';
import DialogHeader from '../../../components/common/DialogHeader';
import { checkAccessforUser } from '../../../helpers/Tools';
import { LoadingButton } from '@mui/lab';
import { UpdateAction } from '../../../clients/models/UpdateAction';

export interface IUpdateUserRolesModalProps {
    open: boolean
    user: User
    setUser(user: User): void
    onClose(): void
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const UpdateUserRolesModal: React.FunctionComponent<IUpdateUserRolesModalProps> = (props: IUpdateUserRolesModalProps) => {

    const [selectedRoles, setSelectedRoles] = React.useState<Role[]>([])

    const [loading, setLoading] = React.useState(false)

    const userApiClient = useUserApiClient()

    const onClose = () => {
        props.onClose()
        setSelectedRoles([])
        setLoading(false)
    }

    const isMobile = window.screen.width < 400

    if (!props.user) {
        return <>
            <Modal
                open={props.open}
                onClose={onClose}
            >
                <Box component="div" sx={{
                    position: 'fixed',
                    top: '20%',
                    left: isMobile ? '20%' : '30%',
                    height: "60%",
                    width: isMobile ? '60%' : "40%",
                    borderRadius: "1em",
                    backgroundColor: theme => theme.palette.background.paper,
                    overflow: "auto"
                }} >
                    <Stack>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                    </Stack>
                </Box>
            </Modal>
        </>
    }

    return (
        <>

            <Dialog
                open={props.open}
                onClose={onClose}
                scroll="paper"
                maxWidth="sm"
            >
                <DialogHeader
                    closeDialog={() => onClose()}
                    headline="Add User Roles"
                />
                <DialogContent dividers>
                    <Grid sx={{ overflow: "auto", paddingBottom: "2em", paddingTop: "2em" }} container>
                        <Grid item xs={12}>
                            <FormControl sx={{ width: 300 }}>
                                <InputLabel id="select-roles-label">Select Roles</InputLabel>
                                <Select
                                    labelId="select-roles-label"
                                    multiple
                                    value={selectedRoles}
                                    onChange={(e: SelectChangeEvent<typeof selectedRoles>) => {
                                        setSelectedRoles(typeof e.target.value === 'string' ? e.target.value.split(',').map(val => val as Role) : e.target.value)
                                    }}
                                    input={<OutlinedInput label="Select Roles" />}
                                    renderValue={(selected) => (
                                        <Box component="div">
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {Object.values(Role)
                                        .filter(role => !checkAccessforUser(props.user, [role]))
                                        .map((role) => (
                                            <MenuItem
                                                key={role}
                                                value={role}
                                            >
                                                {role}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{
                    padding: "12px",
                    justifyContent: "space-between"
                }}>
                    <LoadingButton
                        color="primary"
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        onClick={() => {
                            onClose()
                        }}
                    >
                        Cancel
                    </LoadingButton>
                    <LoadingButton
                        loading={loading}
                        startIcon={<AddIcon />}
                        disabled={selectedRoles.length === 0} onClick={() => {
                            setLoading(true)
                            userApiClient.updateUserRoles(props.user.id, selectedRoles, UpdateAction.ADD)
                                .then((user: User) => {
                                    props.setUser(user)
                                    onClose()
                                })
                                .finally(() => setLoading(false))
                        }}
                        variant="contained"
                        color="info"
                    >
                        Add Roles
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default UpdateUserRolesModal;