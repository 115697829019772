import { Box, Grid, Tab } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { User } from '../../../clients/models/User';
import { useUserApiClient } from '../../../clients/UserApiClient';
import GlobalLoader from '../../../components/common/GlobalLoader';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import UserGeneral from './UserGeneral';
import UserTimeStats from './UserTimeStats';

export interface IUsersOverviewProps {
}

const UserOverview: React.FunctionComponent<IUsersOverviewProps> = (props: IUsersOverviewProps) => {
    const [user, setUser] = useState<User>()
    const [loaded, setLoaded] = useState(false)

    const { userId, tab } = useParams()
    const userApiClient = useUserApiClient()
    const navigate = useNavigate()

    const sanitizedTabValue = (val?: string) => {
        if (val !== "stats") {
            return "overview"
        }
        return val
    }

    const handleTabChange = (_e: React.SyntheticEvent, newValue: string) => {
        navigate(`/users/${userId}/${newValue}`)
    }


    useEffect(() => {
        if (userId) {
            userApiClient.getUser(userId)
                .then(user => setUser(user))
                .finally(() => setLoaded(true))
        }
    }, [userApiClient, userId])

    if (!user) {
        if (!loaded) {
            return <GlobalLoader />
        } else {

            return <>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4">User not found</Typography>
                    </Grid>
                </Grid>
            </>
        }
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4">{user.username}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TabContext value={sanitizedTabValue(tab)}>
                        <Grid container justifyContent="center">
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', overflow: "auto" }}>
                                <TabList onChange={handleTabChange} >
                                    <Tab value="overview" label="General" />
                                    <Tab value="stats" label="Time Stats" />
                                </TabList>
                            </Box>
                        </Grid>
                        <Grid item xs={12} >
                            <TabPanel value="overview" >
                                <UserGeneral user={user} setUser={setUser} />
                            </TabPanel>
                            <TabPanel value="stats" >
                                <UserTimeStats user={user} setUser={setUser} />
                            </TabPanel>
                        </Grid>
                    </TabContext>
                </Grid>
            </Grid >
        </>
    );
}

export default UserOverview;