import { IconButton, SxProps, TextField } from '@mui/material';
import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';

export interface IEditableTextFieldProps {
    label: string,
    value?: string,
    type?: string
    sx?: SxProps
    disabled?: boolean

    onValueChangeSubmit(newValue?: string): void
}

const EditableTextField: React.FunctionComponent<IEditableTextFieldProps> = (props: IEditableTextFieldProps) => {
    const [editable, setEditable] = React.useState(false)
    const [value, setValue] = React.useState(props.value)

    const onSubmit = () => {
        props.onValueChangeSubmit(value)
        setEditable(false)
    }

    return (
        <>
            <TextField
                InputProps={{
                    endAdornment: !props.disabled && <IconButton onClick={() => {
                        if (!editable) {
                            setEditable(true)
                        } else {
                            onSubmit()
                        }
                    }}>
                        {editable ? <CheckIcon /> : <EditIcon />}
                    </IconButton>
                }}
                fullWidth
                disabled={!editable || props.disabled}
                label={props.label}
                sx={props.sx}
                value={value}
                // defaultValue={value}
                type={'text'}
                onChange={(e) => {
                    if (props.type === "number") {
                        setValue(e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1'))
                    } else {
                        setValue(e.target.value)
                    }
                }}
                onBlur={onSubmit}
            />
        </>
    );
}

export default EditableTextField;