import { Button, Grid } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Project } from '../../../clients/models/Project';
import { useProjectApiClient } from '../../../clients/ProjectClient';
import ConfirmDialog from '../../../components/common/ConfirmDialog';
import EditableTextField from '../../../components/common/EditableTextField';
import ExportProjectModal from './ExportProjectModal';
import { useUserContext } from '../../../contexts/UserContext';
import { Role } from '../../../clients/models/Role';
import DisableTooltip from '../../../components/common/DisableTooltip';

export interface IProjectGeneralProps {
    project: Project
    setProject(p: Project): void
}

const ProjectGeneral: React.FunctionComponent<IProjectGeneralProps> = (props: IProjectGeneralProps) => {
    const projectClient = useProjectApiClient()
    const navigate = useNavigate()

    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [exportodalOpen, setExportModalOpen] = useState(false)

    const userCtx = useUserContext()

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <EditableTextField disabled={!userCtx.userHasAccess(Role.ADMIN)} label={'Name'} value={props.project.name} onValueChangeSubmit={(newValue) => {
                        projectClient.updateProject(props.project.id, { name: newValue })
                            .then(p => props.setProject(p))
                    }} />
                </Grid>
                <Grid item xs={12}>
                    <EditableTextField disabled={!userCtx.userHasAccess(Role.ADMIN)} label={'Description'} value={props.project.description} onValueChangeSubmit={(newValue) => {
                        projectClient.updateProject(props.project.id, { description: newValue })
                            .then(p => props.setProject(p))
                    }} />
                </Grid>
                <Grid item xs={12} container>
                    <DisableTooltip
                        disabled={!userCtx.userHasAccess(Role.ADMIN)}
                        disabledText='You have no permissions to export the project.'
                    >
                        <Button
                            disabled={!userCtx.userHasAccess(Role.ADMIN)}
                            color="info"
                            variant="outlined"
                            onClick={() => setExportModalOpen(true)}
                        >Export Project as CSV</Button>
                    </DisableTooltip>
                </Grid>
                <Grid item xs={12} container>
                    <DisableTooltip
                        disabled={!userCtx.userHasAccess(Role.ADMIN)}
                        disabledText='You have no permissions to delete the project.'
                    >
                        <Button
                            disabled={!userCtx.userHasAccess(Role.ADMIN)}
                            color="error"
                            variant="outlined"
                            onClick={() => setDeleteModalOpen(true)}
                        >Delete Project</Button>
                    </DisableTooltip>
                </Grid>
            </Grid>
            <ConfirmDialog
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                confirmQuestion={"Do you really want to delete this project? This action can't be undone! You will loose all data including the time entries."}
                confirmPhrase={props.project.name}
                onConfirm={() => projectClient.deleteProject(props.project.id)
                    .then(() => navigate('/projects'))}
                severity={'error'}
            />
            <ExportProjectModal project={props.project} open={exportodalOpen} onClose={() => setExportModalOpen(false)} />
        </>
    );
}

export default ProjectGeneral;