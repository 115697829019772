import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { SxProps } from "@mui/material/styles";
import React, { useState } from "react";


interface IPasswordInputProps {
    password: string;
    onPasswordChange(nextPassword: string): void;
    onBlur?(): void
    onSubmit?(): void
    errortext?: string;
    error?: boolean;
    optional?: boolean;
    disabled?: boolean;
    title?: string
    size?: "small" | "medium"
    sx?: SxProps
}

const PasswordInput: React.FunctionComponent<IPasswordInputProps> = (props: IPasswordInputProps) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <TextField
            size={props.size}
            disabled={props.disabled}
            sx={props.sx}
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            label={props.title ?? "Password"}
            value={props.password}
            onSubmit={props.onSubmit}
            onChange={(e) => props.onPasswordChange(e.target.value)}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton
                        disabled={props.disabled}
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                    >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            }}
            onBlur={props.onBlur}
            error={props.error}
            helperText={props.error ? props.errortext : ""}
        />
    );

}

export default PasswordInput