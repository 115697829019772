import { Add, Delete, Visibility } from '@mui/icons-material';
import { Autocomplete } from '@mui/lab';
import { Fab, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Project } from '../../../clients/models/Project';
import { UpdateAction } from '../../../clients/models/UpdateAction';
import { User } from '../../../clients/models/User';
import { useProjectApiClient } from '../../../clients/ProjectClient';
import { useUserApiClient } from '../../../clients/UserApiClient';

export interface IProjectUsersProps {
    project: Project
    setProject(p: Project): void
}

const ProjectUsers: React.FunctionComponent<IProjectUsersProps> = (props: IProjectUsersProps) => {
    const userClient = useUserApiClient()
    const projectClient = useProjectApiClient()

    const navigate = useNavigate()

    const [submitAddUsersLoading, setSubmitAddUsersLoading] = useState(false)

    const [matchedUsers, setMatchedUsers] = useState<User[]>([])
    const [addUsers, setAddUsers] = useState<User[]>([])

    const onSearchChange = (term: string) => {
        if (term) {
            userClient.searchUser(term, 0, 20)
                .then(page => setMatchedUsers(page.users))
        } else {
            setMatchedUsers([])
        }
    }

    const submitUsers = () => {
        if (addUsers.length > 0 && !submitAddUsersLoading) {
            setSubmitAddUsersLoading(true)
            projectClient.updatePermittedUsers(props.project.id, {
                action: UpdateAction.ADD,
                userIds: addUsers.map(u => u.id)
            })
                .then(p => props.setProject(p))
                .then(() => setAddUsers([]))
                .finally(() => setSubmitAddUsersLoading(false))
        }
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={11}>
                    <Autocomplete
                        size="small"
                        disablePortal
                        multiple
                        clearOnBlur
                        selectOnFocus
                        value={addUsers}
                        onBlur={() => onSearchChange("")}
                        onChange={(_e, users, _reason) => {
                            setAddUsers(users)
                            onSearchChange("")
                        }}
                        options={matchedUsers}
                        filterOptions={(options, _state) => options}
                        getOptionLabel={user => `${user.username}`}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <TextField
                            {...params}
                            label='Search to add users'
                            fullWidth
                            onChange={e => onSearchChange(e.target.value)}
                        />}
                    />
                </Grid>
                <Grid item xs={1} container justifyContent="centers">
                    <Grid item>
                        < Fab
                            size="small"
                            disabled={addUsers.length === 0 || submitAddUsersLoading}
                            onClick={submitUsers}
                            color="success" >
                            {submitAddUsersLoading ? <CircularProgress /> : <Add />}
                        </Fab>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Permitted Users</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {props.project.permittedUsers?.map((user) => {
                                return <TableRow hover key={user.id}>
                                    <TableCell align="left">
                                        <Tooltip title="See User">
                                            <IconButton
                                                color="info"
                                                size='small'
                                                onClick={() => {
                                                    navigate(`/users/${user.id}`)
                                                }}
                                            >
                                                <Visibility />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Remove User from Project">
                                            <IconButton
                                                color="error"
                                                size='small'
                                                onClick={() => {
                                                    projectClient.updatePermittedUsers(props.project.id, {
                                                        action: UpdateAction.REMOVE,
                                                        userIds: [user.id]
                                                    })
                                                        .then(p => props.setProject(p))
                                                }}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell > {user.username}</TableCell>
                                </TableRow>
                            })}
                            {(props.project.permittedUsers?.length ?? 0) === 0 && <TableRow>
                                <TableCell colSpan={2}>
                                    <Typography align="center" variant="body1"><i>You have no permitted users on this project yet.</i></Typography>
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </>
    );
}

export default ProjectUsers;