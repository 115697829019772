import { Grid, Paper } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import FormInput from '../../../components/common/FormInput';

export interface ICreateProjectStepOneProps {
    name: string
    setName(name: string): void
    description: string
    setDescription(description: string): void

}

const CreateProjectStepOne: React.FunctionComponent<ICreateProjectStepOneProps> = (props: ICreateProjectStepOneProps) => {
    const [name, setName] = useState(props.name)
    const [description, setDescription] = useState(props.description)

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container sx={{ padding: "1em" }}>
                            <Grid container justifyContent="center" spacing={3}>
                                <Grid item xs={12}>
                                    <FormInput input={name} onBlur={() => props.setName(name)} onInputChange={setName} label="Project name" />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormInput input={description} onBlur={() => props.setDescription(description)} onInputChange={setDescription} label="Project description" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default CreateProjectStepOne;