import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Paper, Skeleton, Tab, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Project } from '../../../../clients/models/Project';
import { Topic } from '../../../../clients/models/Topic';
import { useProjectApiClient } from '../../../../clients/ProjectClient';
import BreadCrumbs from '../../../../components/common/BreadCrumbs';
import { useCheckMobileScreen } from '../../../../helpers/MobileHelper';
import TopicGeneral from './TopicGeneral';
import { useUserContext } from '../../../../contexts/UserContext';
import { Role } from '../../../../clients/models/Role';
import { sanitizeStringValue } from '../../../../helpers/Tools';
import TopicEntries from './TopicEntries';

export interface ITopicOverviewProps {
}

const TopicOverview: React.FunctionComponent<ITopicOverviewProps> = (props: ITopicOverviewProps) => {
    const navigate = useNavigate()
    const projectClient = useProjectApiClient()

    const isMobile = useCheckMobileScreen()

    const { projectId, topicId, tab } = useParams()
    const [topic, setTopic] = React.useState<Topic>()
    const [project, setProject] = React.useState<Project>()

    const userCtx = useUserContext()

    const [tabIndex, setTabIndex] = React.useState(userCtx.userHasAccess(Role.ADMIN) ?
        sanitizeStringValue(['overview', 'entries'], 'overview', tab) :
        sanitizeStringValue(['overview'], 'overview', tab))



    if (!projectId) {
        navigate("/projects")
    }
    if (!topicId) {
        navigate(`/projects/${projectId}/topics`)
    }

    const handleTabChange = (_e: React.SyntheticEvent, newValue: string) => {
        setTabIndex(newValue)
        navigate(`/projects/${projectId}/topics/${topicId}/${newValue}`)
    }

    React.useEffect(() => {
        if (projectId && topicId) {
            let projectPresent = false
            projectClient.getProject(projectId)
                .then(p => {
                    setProject(p)
                    p.topics.forEach(t => {
                        if (t.id === topicId) {
                            setTopic(t)
                            projectPresent = true
                        }
                    })
                })
                .then(() => !projectPresent && navigate(`/projects/${projectId}/topics`))
        }
    }, [navigate, projectClient, projectId, topicId])

    if (!topic || !project) {
        return (<>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Skeleton variant='text' />
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container sx={{ padding: "1em" }}>
                            <Grid item xs={12} container spacing={1}>
                                <Grid item xs={12}>
                                    <Skeleton variant='rounded' />
                                </Grid>
                                <Grid item xs={12}>
                                    <Skeleton variant='rounded' />
                                </Grid>
                                <Grid item xs={12}>
                                    <Skeleton variant='rounded' />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>)
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <BreadCrumbs breadcrumbs={[
                        { to: "/projects", title: "Projects" },
                        { to: `/projects/${project.id}/topics`, title: project.name },
                        { title: topic.name },
                    ]} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center"><b> {project.name} -</b> {topic.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container sx={{ padding: isMobile ? "0" : "1em" }}>
                            <Grid item xs={12} container spacing={3}>
                                <TabContext value={tabIndex}>
                                    <Grid item xs={12} container justifyContent="center">
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleTabChange}>
                                                <Tab value="overview" label="General" />
                                                {userCtx.userHasAccess(Role.ADMIN) && <Tab value="entries" label="Time Entries" />}
                                            </TabList>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TabPanel value="overview" >
                                            <TopicGeneral topic={topic} project={project} setTopic={setTopic} />
                                        </TabPanel>
                                        <TabPanel value="entries" >
                                            <TopicEntries topic={topic} project={project} />
                                        </TabPanel>
                                    </Grid>
                                </TabContext>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default TopicOverview;