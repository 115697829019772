import { Add } from '@mui/icons-material';
import { Grid, IconButton, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Project } from '../../../clients/models/Project';
import { useProjectApiClient } from '../../../clients/ProjectClient';
import { useTopicApiClient } from '../../../clients/TopicClient';
import { useCheckMobileScreen } from '../../../helpers/MobileHelper';

export interface IProjectTopicsProps {
    project: Project
    setProject(p: Project): void
}

const ProjectTopics: React.FunctionComponent<IProjectTopicsProps> = (props: IProjectTopicsProps) => {
    const projectClient = useProjectApiClient()
    const topicClient = useTopicApiClient()
    const navigate = useNavigate()

    const [newTopicName, setNewTopicName] = useState("")
    const [newTopicDesc, setNewTopicDesc] = useState("")

    const [showArchived, setShowArchived] = useState(false)

    const isMobile = useCheckMobileScreen()

    const updateProject = () => projectClient.getProject(props.project.id).then(p => props.setProject(p))

    const addTopic = () => {
        if (newTopicName) {
            topicClient.addTopic(props.project.id, { name: newTopicName, description: newTopicDesc })
                .then(() => updateProject())
            setNewTopicDesc("")
            setNewTopicName("")
        }
    }

    return (
        <>
            <Grid container rowSpacing={2} columnGap={1}>
                <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
                    <Grid item sx={{ marginRight: "1em" }}>
                        <Typography variant="body1">
                            Show archived topics:
                        </Typography>
                    </Grid>
                    <Grid item><Switch checked={showArchived} onChange={(e, checked) => setShowArchived(checked)} /></Grid>
                </Grid>
                <Grid item xs={12} sx={{ maxHeight: "20em", overflow: "auto" }}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: isMobile ? "50%" : "33%" }}>Name</TableCell>
                                <TableCell sx={{ width: isMobile ? "50%" : "33%" }}>Budget</TableCell>
                                {!isMobile && <TableCell sx={{ width: "33%" }} >Description</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {props.project.topics.map((topic) => {
                                if (!showArchived && topic.disabled) {
                                    return undefined
                                }
                                return <TableRow sx={{ ':hover': { cursor: 'pointer' } }}
                                    onClick={() => {
                                        navigate(`/projects/${props.project.id}/topics/${topic.id}`)
                                    }}
                                    hover
                                    key={topic.id}>
                                    <TableCell> {topic.name}</TableCell>
                                    <TableCell sx={[((topic.budget ?? 0) > 0 && (topic.totalHours ?? 0) > (topic.budget ?? 0)) && {
                                        color: (theme) => theme.palette.error.main
                                    }]}> {(topic.totalHours ?? 0).toFixed(2)} / {(topic.budget ?? 0) > 0 ? topic.budget?.toFixed() : <>&infin;</>} </TableCell>
                                    {!isMobile && <TableCell>{topic.description ? topic.description : "---"}</TableCell>}
                                </TableRow>
                            })}
                            {props.project.topics.filter(t => showArchived || !t.disabled).length === 0 &&
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <Typography variant="body1" align='center'>
                                            <i>
                                                {props.project.topics.filter(t => showArchived || !t.disabled).length < props.project.topics.length ? 'To see archived topics, enable the show archive toggle.' : 'No topics created yet.'}
                                            </i>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={1}>
                    <IconButton
                        color="success"
                        disabled={!newTopicName}
                        onClick={addTopic}
                        title='Add topic'
                        size="small">
                        <Add />
                    </IconButton>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        value={newTopicName}
                        onChange={e => setNewTopicName(e.target.value)}
                        size='small'
                        label="Name *" />
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        value={newTopicDesc}
                        onChange={e => setNewTopicDesc(e.target.value)}
                        size='small'
                        label="Description" />
                </Grid>
            </Grid >
        </>
    );
}

export default ProjectTopics;