import { DialogTitle, Grid, Typography, IconButton } from '@mui/material';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { SxProps } from '@mui/material/styles';

export interface IDialogHeaderProps {
    headline: string
    sx?: SxProps
    closeDialog?(): void
}

const DialogHeader: React.FunctionComponent<IDialogHeaderProps> = (props: IDialogHeaderProps) => {
    return (
        <DialogTitle sx={props.sx}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={9}>
                    <Typography align='left' variant="h5"><b>{props.headline}</b></Typography>
                </Grid>
                <Grid item xs={3} textAlign="right">
                    <IconButton
                        onClick={props.closeDialog}
                        color="inherit"
                        sx={{ fontSize: "22px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </DialogTitle>
    );
}

export default DialogHeader;