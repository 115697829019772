import * as React from 'react';
import { Box, Breadcrumbs } from '@mui/material'
import { Link } from 'react-router-dom';
import { NavigateNext } from '@mui/icons-material';

export interface BreadCrumb {
    to?: string,
    title: string
}

export interface IBreadCrumbsProps {
    breadcrumbs: BreadCrumb[]
}

const BreadCrumbs: React.FunctionComponent<IBreadCrumbsProps> = (props: IBreadCrumbsProps) => {
    return (
        <>
            <Breadcrumbs separator={<NavigateNext />} >
                {props.breadcrumbs.map(b => b.to
                    ? (<Box sx={{ textDecoration: "none" }} component={Link} key={b.to} to={b.to}>{b.title}</Box>)
                    : (<Box key={b.title}>{b.title}</Box>)
                )}
            </Breadcrumbs>
        </>
    );
}

export default BreadCrumbs;