import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';

export interface ICollapsableRowProps {
    defaultOpen?: boolean
    collapsedContent?: JSX.Element
    numberOfCols: number
}

const CollapsableRow: React.FunctionComponent<React.PropsWithChildren<ICollapsableRowProps>> = (props: React.PropsWithChildren<ICollapsableRowProps>) => {
    const [open, setOpen] = useState(props.defaultOpen)
    return (
        <>
            <TableRow hover sx={{ ':hover': { cursor: "pointer" } }} onClick={() => setOpen(!open)}>
                <TableCell >
                    <IconButton
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                {props.children}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={props.numberOfCols + 1}>
                    <Collapse in={open} timeout="auto" >
                        {props.collapsedContent}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default CollapsableRow;