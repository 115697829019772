import { Button, Grid, Switch, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Project } from '../../../../clients/models/Project';
import { Topic } from '../../../../clients/models/Topic';
import { useTopicApiClient } from '../../../../clients/TopicClient';
import ConfirmDialog from '../../../../components/common/ConfirmDialog';
import EditableTextField from '../../../../components/common/EditableTextField';

export interface ITopicGeneralProps {
    topic: Topic
    project: Project
    setTopic(t: Topic): void
}

const TopicGeneral: React.FunctionComponent<ITopicGeneralProps> = (props: ITopicGeneralProps) => {
    const topicClient = useTopicApiClient()
    const navigate = useNavigate()

    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <EditableTextField label={'Name'} value={props.topic.name} onValueChangeSubmit={(newValue) => {
                        topicClient.updateTopic(props.project.id, props.topic.id, { name: newValue })
                            .then(t => props.setTopic(t))
                    }} />
                </Grid>
                <Grid item xs={12}>
                    <EditableTextField label={'Description'} value={props.topic.description} onValueChangeSubmit={(newValue) => {
                        topicClient.updateTopic(props.project.id, props.topic.id, { description: newValue })
                            .then(t => props.setTopic(t))
                    }} />
                </Grid>
                <Grid item xs={12}>
                    <EditableTextField label={'Budget in hours'} value={(props.topic.budget ?? 0) + ""} type="number" onValueChangeSubmit={(newValue) => {
                        topicClient.updateTopic(props.project.id, props.topic.id, { budget: +(newValue ?? 0) })
                            .then(t => props.setTopic(t))
                    }} />
                </Grid>
                <Grid item xs={12} container alignItems="center">
                    <Grid item>
                        <Switch checked={!props.topic.disabled} onChange={() => {
                            topicClient.updateTopic(props.project.id, props.topic.id, { disabled: !props.topic.disabled })
                                .then((t) => props.setTopic(t))
                        }} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">Active</Typography>
                        <Typography variant="body2">Users can book times to active topics only.</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="error"
                        variant="outlined"
                        onClick={() => setDeleteModalOpen(true)}
                    >Delete Topic</Button>
                </Grid>
            </Grid>
            <ConfirmDialog
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                confirmQuestion={"Do you really want to delete this topic? This action can't be undone! You will loose all data including time entries!"}
                confirmPhrase={props.project.name + "-" + props.topic.name}
                onConfirm={() => topicClient.deleteTopic(props.project.id, props.topic.id)
                    .then(() => navigate(`/projects/${props.project.id}/topics`))}
                severity={'error'}
            />
        </>)
}

export default TopicGeneral;