import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Skeleton, Box, Grid, Paper, Tab, Typography, Button } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Project } from '../../../clients/models/Project';
import { useProjectApiClient } from '../../../clients/ProjectClient';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import { useCheckMobileScreen } from '../../../helpers/MobileHelper';
import ProjectGeneralInformation from './ProjectGeneral';
import ProjectTimeStats from './ProjectTimeStats';
import ProjectTopics from './ProjectTopics';
import ProjectUsers from './ProjectUsers';
import ProjectCustomers from './ProjectCustomers';
import { useUserContext } from '../../../contexts/UserContext';
import { Role } from '../../../clients/models/Role';
import { sanitizeStringValue } from '../../../helpers/Tools';
import { ErrorResponse, ErrorType } from '../../../clients/models/errors/ErrorResponse';
import { useNotificationContext } from '../../../contexts/NotificationContext';

export interface IProjectProps {
}

const ProjectOverview: React.FunctionComponent<IProjectProps> = (props: IProjectProps) => {
    const navigate = useNavigate()
    const projectClient = useProjectApiClient()
    const isMobile = useCheckMobileScreen()

    const { projectId, tab } = useParams()
    const [project, setProject] = useState<Project>()
    const [error, setError] = useState<string>()


    const userCtx = useUserContext()

    const notifcationCtx = useNotificationContext()

    const isAdmin = userCtx.userHasAccess(Role.ADMIN)

    const [tabIndex, setTabIndex] = useState(isAdmin ?
        sanitizeStringValue(["topics", "overview", "stats", "users", "customers"], "overview", tab) :
        sanitizeStringValue(["topics", "overview", "stats"], "overview", tab))



    if (!projectId) {
        navigate("/projects")
    }

    const handleTabChange = (_e: React.SyntheticEvent, newValue: string) => {
        setTabIndex(newValue)
        navigate(`/projects/${projectId}/${newValue}`)
    }

    React.useEffect(() => {
        if (projectId) {
            projectClient.getProject(projectId, { preventNotification: true })
                .then(p => setProject(p))
                .catch((e: ErrorResponse) => {
                    if (e.type === ErrorType.ENTITY_NOT_FOUND || e.type === ErrorType.ACCESS_DENIED) {
                        setError("Project does not exist or you don't have permission to see it.")
                    } else {
                        setError(e.message)
                        notifcationCtx.notify({ message: e.message, severity: 'error' })
                    }
                })
        }
    }, [notifcationCtx, projectClient, projectId])

    if (error) {
        return (<>
            <Paper>
                <Grid container sx={{ padding: "1em" }}>
                    <Grid item xs={12} container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h4" align='center'>Whoops!</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" align='center'>{error}</Typography>
                        </Grid>
                        <Grid item xs={12} container justifyContent="center">
                            <Button variant="contained" onClick={() => navigate("/projects")}>go back</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>)
    }

    if (!project) {
        return (<>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Skeleton variant='text' />
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container sx={{ padding: "1em" }}>
                            <Grid item xs={12} container spacing={1}>
                                <Grid item xs={12}>
                                    <Skeleton variant='rounded' />
                                </Grid>
                                <Grid item xs={12}>
                                    <Skeleton variant='rounded' />
                                </Grid>
                                <Grid item xs={12}>
                                    <Skeleton variant='rounded' />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>)
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <BreadCrumbs breadcrumbs={[
                        { to: "/projects", title: "Projects" },
                        { title: project.name },
                    ]} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center"><b>{project.name}</b></Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container sx={{ padding: isMobile ? "0" : "1em" }}>
                            <TabContext value={tabIndex}>
                                <Grid container justifyContent="center">
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', overflow: "auto" }}>
                                        <TabList
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            onChange={handleTabChange} >
                                            <Tab value="overview" label="General" />
                                            <Tab value="topics" label="Topics" />
                                            {isAdmin && <Tab value="users" label="Users" />}
                                            {isAdmin && <Tab value="customers" label="Customers" />}
                                            <Tab value="stats" label="Time Stats" />
                                        </TabList>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} >
                                    <TabPanel value="overview" >
                                        <ProjectGeneralInformation project={project} setProject={setProject} />
                                    </TabPanel>
                                    <TabPanel value="topics" >
                                        <ProjectTopics project={project} setProject={setProject} />
                                    </TabPanel>
                                    <TabPanel value="users" >
                                        <ProjectUsers project={project} setProject={setProject} />
                                    </TabPanel>
                                    <TabPanel value="customers" >
                                        <ProjectCustomers project={project} setProject={setProject} />
                                    </TabPanel>
                                    <TabPanel value="stats" >
                                        <ProjectTimeStats project={project} setProject={setProject} />
                                    </TabPanel>
                                </Grid>
                            </TabContext>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default ProjectOverview;