import * as React from 'react';
import { CircularProgress, Fab, Grid, IconButton, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Add, Search } from '@mui/icons-material';
import { useProjectApiClient } from '../../clients/ProjectClient';
import { ProjectPagination } from '../../clients/models/ProjectPagination';
import { Link, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext';
import { Role } from '../../clients/models/Role';
import { DataGrid, GridCallbackDetails, GridPaginationModel } from '@mui/x-data-grid';

export interface IProjectProps { }
const Projects: React.FunctionComponent<IProjectProps> = (props: IProjectProps) => {
    const [searchTerm, setSearchTerm] = useState("")
    const [projects, setProjects] = useState<ProjectPagination>()
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)


    const projectClient = useProjectApiClient()

    const userCtx = useUserContext()

    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        const fetchMethod = () => searchTerm ? projectClient.searchProject(searchTerm, page, pageSize) : projectClient.getAllProjects(page, pageSize)
        fetchMethod()
            .then(res => setProjects(res))
            .finally(() => setLoading(false))
    }, [page, pageSize, projectClient, searchTerm])

    const defaultRowDefinition = {
        disableColumnMenu: true, sortable: false, filterable: false
    }

    return (<>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper>
                    <Grid container sx={{ padding: "1em" }}>
                        <Grid item xs={12} container spacing={1}>
                            <Grid item xs={11}>
                                <Typography variant='h5'>Search Projects</Typography>
                            </Grid>
                            <Grid item xs={1} container justifyContent="flex-end">
                                {loading && <CircularProgress size={25} />}
                            </Grid>
                            <Grid item xs={10} md={11} container alignContent="center">
                                <TextField value={searchTerm} onChange={e => {
                                    setSearchTerm(e.target.value)
                                }} sx={{ width: "100%" }} size='small' label="Search projects by name or description" type="search" />
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <IconButton >
                                    <Search />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{
                    height: "60vh",
                    padding: "1em",
                    display: "flex",
                    flexDirection: "column",
                    msFlexDirection: "column",
                }}>
                    <Grid container sx={{ marginBottom: "0.5em" }}>
                        <Grid item xs={9}>
                            <Typography variant='h5'>Projects</Typography>
                        </Grid>
                        {userCtx.userHasAccess(Role.ADMIN) &&
                            < Grid item xs={3} md={3} container justifyContent="flex-end">
                                <Tooltip title="Add new project">
                                    <Fab size="small" component={Link} to="/projects/create" color="success" >
                                        <Add />
                                    </Fab>
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>
                    <DataGrid columns={[
                        { field: 'name', headerName: 'Name', flex: 0.4, ...defaultRowDefinition },
                        { field: 'description', headerName: 'Description', flex: 0.4, ...defaultRowDefinition },
                        { field: 'topics', headerName: 'Topics', flex: 0.2, valueGetter: (_value, row) => row.topics.length, ...defaultRowDefinition }
                    ]}
                        disableColumnSelector
                        rows={projects?.projects ?? []}
                        autoPageSize
                        paginationMode="server"
                        rowCount={projects?.totalResults ?? 0}

                        onPaginationModelChange={(model: GridPaginationModel, details: GridCallbackDetails) => {
                            setPageSize(model.pageSize)
                            setPage(model.page)
                        }}
                        onRowClick={(row) => navigate(`/projects/${row.id}`)}
                    />

                </Paper >
            </Grid >
        </Grid >
    </>);
}

export default Projects;