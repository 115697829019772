import { Box, Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useUserContext } from '../contexts/UserContext';
import { useCheckMobileScreen } from '../helpers/MobileHelper';
import { Link } from 'react-router-dom';

export interface IHomeProps {

}


const Home: React.FunctionComponent<IHomeProps> = (props: IHomeProps) => {
    const isMobile = useCheckMobileScreen()

    const userCtx = useUserContext()


    return (
        <>
            <Grid container justifyContent="center" rowSpacing={3}>
                <Grid item xs={12} container justifyContent="center" alignContent={"center"} >
                    <Box component="img" sx={{ height: isMobile ? "10em" : "7.5em" }} src="svg/icon_no_background.svg" />
                </Grid>
                <Grid item xs={12}><br /></Grid>
                <Grid item xs={12}>
                    <Typography align="center" variant="h3">Welcome to Times!</Typography>
                </Grid>
                {userCtx.isLoggedIn() ? <>
                    <Grid item xs={12}>
                        <Typography align="center" variant="h5">Hey {userCtx.user!.username}</Typography>
                    </Grid>
                    <Grid item xs={12} container justifyContent="center">
                        <Button component={Link} to="/goto" variant="outlined">Go to App</Button>
                    </Grid>
                </> : <>
                    <Grid item xs={12}>
                        <Typography align="center" variant="h5">Already have an account?</Typography>
                    </Grid>
                    <Grid item xs={12} container justifyContent="center">
                        <Button component={Link} to="/goto" variant="outlined">Login!</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center" variant="h5">If you&apos;re new to Times, feel free to register!</Typography>
                    </Grid>
                    <Grid item xs={12} container justifyContent="center">
                        <Button component={Link} to="/register" variant="contained">Register!</Button>
                    </Grid>
                </>}
            </Grid>
        </>
    );
}

export default Home;