
import { Accordion, AccordionDetails, AccordionSummary, Chip, Grid, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import { useState } from 'react';
import 'dayjs/locale/en-gb';
import { getEndOfMonth, getStartOfMonth } from '../../helpers/TimeHelper';

export interface ITimeRangeSelectorProps {
    setStart(start: Dayjs | null): void
    setEnd(end: Dayjs | null): void
    start: Dayjs | null
    end: Dayjs | null
}

const TimeRangeSelector: React.FunctionComponent<ITimeRangeSelectorProps> = (props: ITimeRangeSelectorProps) => {
    const [expanded, setExpanded] = useState(true)

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <Accordion expanded={expanded} onChange={(_e, exp) => setExpanded(exp)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}>
                        {expanded ?
                            <Typography width="100%" align="center">Select date range</Typography>
                            : <Typography width="100%" align="center">{props.start?.format('DD/MM/YYYY')} - {props.end?.format('DD/MM/YYYY')} </Typography>
                        }

                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} container justifyContent={"center"}>
                                <DatePicker
                                    value={props.start}
                                    label="Start Date"
                                    onChange={(val) => props.setStart(val)}
                                    slotProps={{
                                        textField: {
                                            helperText: 'DD / MM / YYYY',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} container justifyContent={"center"}>
                                <DatePicker
                                    label={"End Date"}
                                    value={props.end}
                                    onChange={(val) => props.setEnd(val)}
                                    slotProps={{
                                        textField: {
                                            helperText: 'DD / MM / YYYY',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} container justifyContent={"center"}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" align='center'>Quick Select</Typography>
                                </Grid>
                                <Grid item xs={12} container spacing={2} justifyContent={"center"}>
                                    <Grid item>
                                        <Chip onClick={() => {
                                            props.setStart(getStartOfMonth(dayjs()))
                                            props.setEnd(getEndOfMonth(dayjs()))
                                        }} label="This Month" />
                                    </Grid>
                                    <Grid item>
                                        <Chip onClick={() => {
                                            props.setStart(getStartOfMonth(dayjs().subtract(1, 'month')))
                                            props.setEnd(getEndOfMonth(dayjs().subtract(1, 'month')))
                                        }} label="Last Month" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </LocalizationProvider>
        </>
    );
}

export default TimeRangeSelector;