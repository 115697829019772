import * as React from 'react';
import { NotificationAlert } from '../../contexts/NotificationContext';
import { Alert } from '@mui/material';

export interface INotificationAlertProps {
    notification: NotificationAlert
}

const DisplayNotificationAlert: React.FunctionComponent<INotificationAlertProps> = (props: INotificationAlertProps) => {
    return (
        <>
            <Alert severity={props.notification.severity}>
                {props.notification.message}
            </Alert>
        </>
    );
}

export default DisplayNotificationAlert;