import { Grid, Paper } from '@mui/material';
import * as React from 'react';
import { User } from '../../../clients/models/User';

export interface ICreateProjectStepThreeProps {
    users: User[]
    setUsers(users: User[]): void
}

const CreateProjectStepThree: React.FunctionComponent<ICreateProjectStepThreeProps> = (props: ICreateProjectStepThreeProps) => {
    return (
        <>

            <Grid container>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container sx={{ padding: "1em" }}>
                            <Grid container justifyContent="center" spacing={3}>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default CreateProjectStepThree;