import * as React from 'react';
import { Topic, TopicTimeEntries } from '../../../../clients/models/Topic';
import { Project } from '../../../../clients/models/Project';
import { useAdminStatsClient } from '../../../../clients/AdminStatsClient';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { getStartOfMonth, getEndOfMonth } from '../../../../helpers/TimeHelper';
import { Grid, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import TimeRangeSelector from '../../../../components/times/TimeRangeSelector';

export interface ITopicTimeEntriesProps {
    topic: Topic
    project: Project
}

const TopicEntries: React.FunctionComponent<ITopicTimeEntriesProps> = (props: ITopicTimeEntriesProps) => {
    const statsClient = useAdminStatsClient()

    const [start, setStart] = useState<Dayjs | null>(getStartOfMonth(dayjs()))
    const [end, setEnd] = useState<Dayjs | null>(getEndOfMonth(dayjs()))



    const [timeEntries, setTimeEntries] = useState<TopicTimeEntries>()
    const [statsLoading, setStatsLoading] = useState(false)

    useEffect(() => {
        setStatsLoading(true)
        statsClient.getTopicEntries(props.project.id, props.topic.id, { start: start ?? dayjs(), end: end ?? dayjs() })
            .then(setTimeEntries)
            .finally(() => setStatsLoading(false))
    }, [end, props.project.id, props.topic.id, start, statsClient])


    return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TimeRangeSelector
                        start={start}
                        end={end}
                        setEnd={setEnd}
                        setStart={setStart}
                    />
                </Grid>
                {statsLoading || !timeEntries ?
                    <Grid item xs={12} sx={{ marginTop: "0.75em" }} >
                        <Stack spacing={3}>
                            <Skeleton variant='rounded' />
                            <Skeleton variant='rounded' />
                            <Skeleton variant='rounded' />
                        </Stack>
                    </Grid> :
                    <Grid item xs={12} sx={{ marginTop: "0.75em", maxHeight: "20em", overflow: "auto" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>User</TableCell>
                                    <TableCell>Hours</TableCell>
                                    <TableCell>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {timeEntries?.timeEntries.map((te) =>
                                    <TableRow key={te.username + te.date}>
                                        <TableCell >{dayjs(te.date).format('DD. MMMM YYYY')}</TableCell>
                                        <TableCell>{te.username ?? '---'}</TableCell>
                                        <TableCell>{te.hours}</TableCell>
                                        <TableCell>{te.description ? te.description : '---'}</TableCell>
                                    </TableRow>
                                )}
                                {(timeEntries?.timeEntries.length ?? 0) === 0 && <TableRow>
                                    <TableCell colSpan={4}>
                                        <Typography align="center" variant="body1"><i>There are no bookings for this time span.</i></Typography>
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </Grid>
                }
            </Grid>
        </>
    );
}

export default TopicEntries;