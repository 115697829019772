import { Email, PowerSettingsNew } from "@mui/icons-material";
import { AppBar, Button, Box, Grid, Toolbar, IconButton, Tooltip, Badge, CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import { useProjectApiClient } from "../../clients/ProjectClient";
import { CustomerProjectRelation } from "../../clients/models/Project";
export interface IHeaderProps {
}

const Header: React.FunctionComponent<IHeaderProps> = (props: IHeaderProps) => {
    const [pendingInvitations, setPendingInvitations] = useState<CustomerProjectRelation[]>()
    const userCtx = useUserContext()

    const projectClient = useProjectApiClient()

    const location = useLocation()

    const pollNotifications = useRef<NodeJS.Timer>()

    const fetchNotifications = useCallback(() => {
        if (userCtx.isLoggedIn()) {
            projectClient.getAllPendingInvitations()
                .then(cpr => setPendingInvitations(cpr))
        }
    }, [projectClient, userCtx])

    useEffect(() => {
        fetchNotifications()
        if (location.pathname === "/notifications") {
            if (!pollNotifications.current) {
                pollNotifications.current = setInterval(() => fetchNotifications(), 5000)
            }
        } else {
            if (pollNotifications.current) {
                clearInterval(pollNotifications.current)
                pollNotifications.current = undefined
            }
        }
    }, [fetchNotifications, location])

    let iconLink = "/"
    if (userCtx.isLoggedIn() && location.pathname !== "/goto" && location.pathname !== "/") {
        iconLink = "/goto"
    }


    return <>
        <AppBar position="sticky">
            <Toolbar>
                <Grid container >
                    <Grid item xs={3} container alignContent="center">
                        <Button color="primary" variant="text" sx={{ width: "2em" }} component={NavLink} to={iconLink} >
                            <Box component="img" src="/svg/icon_no_background.svg" sx={{ width: "100%", height: "100%" }} />
                        </Button>

                    </Grid>
                    <Grid item xs={9} container justifyContent="right" alignContent="center" spacing={2}>
                        {userCtx.user &&
                            <>

                                <Grid item>
                                    <Button color="secondary" variant="text" component={NavLink} to="/notifications" >
                                        <Badge color="error" badgeContent={pendingInvitations ? pendingInvitations.length > 0 ? pendingInvitations.length : undefined : <CircularProgress size="18px" color="info" />} >
                                            <Email />
                                        </Badge>
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button color="secondary" variant="text" component={NavLink} to="/users/me" >
                                        {userCtx.user.username}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Logout">
                                        <IconButton color="secondary" onClick={() => {
                                            userCtx.logout(true)
                                        }}>
                                            <PowerSettingsNew />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    </>
}

export default Header;