import './App.css';
import { createTheme, ThemeProvider } from '@mui/material';
import HttpsRedirect from 'react-https-redirect'
import { UserContextProvider } from './contexts/UserContext';
import Root from './Root';
import * as React from 'react'
import NotificationContextProvider from './contexts/NotificationContext';

const theme = createTheme({
  palette: {
    primary: {
      main: "#2b2e4a",
      dark: "#3e426a"
    },
    secondary: {
      light: "#ffffff",
      main: "#AAABAB",
      dark: "#181a2a"
    },
    background: {
      paper: "#c4c4c4"
    }
  }
});


function App() {
  return (
    <>
      <HttpsRedirect>
        <ThemeProvider theme={theme}>
          <NotificationContextProvider>
            <UserContextProvider>
              <Root />
            </UserContextProvider>
          </NotificationContextProvider>
        </ThemeProvider>
      </HttpsRedirect>
    </>
  );
}

export default App;
