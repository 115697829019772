import * as React from 'react';
import { User } from '../../../clients/models/User';
import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { Role } from '../../../clients/models/Role';
import { UpdateAction } from '../../../clients/models/UpdateAction';
import EditableTextField from '../../../components/common/EditableTextField';
import ConfirmDialog from '../../../components/common/ConfirmDialog';
import PasswordChangeModal from './PasswordChangeModal';
import UpdateUserRolesModal from './UpdateUserRolesModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../../contexts/UserContext';
import { useCheckMobileScreen } from '../../../helpers/MobileHelper';
import { checkAccessforUser } from '../../../helpers/Tools';
import { useUserApiClient } from '../../../clients/UserApiClient';

export interface IUserGeneralProps {
    user: User
    setUser(u: User): void
}

const UserGeneral: React.FunctionComponent<IUserGeneralProps> = (props: IUserGeneralProps) => {
    const [openPasswordChangeModal, setOpenPasswordChangeModal] = useState(false)
    const [openUpdateRolesModal, setOpenUpdateRolesModal] = useState(false)
    const [openConfirmDeletion, setOpenConfirmDeletion] = useState(false)

    const isMobile = useCheckMobileScreen()

    const userCtx = useUserContext()
    const navigate = useNavigate()
    const userApiClient = useUserApiClient()

    const userisAdmin = checkAccessforUser(props.user, [Role.ADMIN])
    const isAdmin = userCtx.userHasAccess(Role.ADMIN)

    return (
        <>

            <Paper>
                <Grid container sx={{ padding: "1em" }}>
                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                User information
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <EditableTextField value={props.user.username} label="Name" onValueChangeSubmit={(newValue) => {
                                userApiClient.updateUser(props.user.id, { username: newValue })
                                    .then(res => props.setUser(res))
                            }} />
                        </Grid>
                        <Grid item xs={12}>
                            <EditableTextField value={props.user.email} label="Email" onValueChangeSubmit={(newValue) => {
                                userApiClient.updateUser(props.user.id, { email: newValue })
                                    .then(res => props.setUser(res))
                            }} />
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={8}>
                                <Typography variant="h6">
                                    Roles
                                </Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="flex-end">
                                <Grid item>
                                    {isAdmin &&
                                        <Tooltip title="Add Roles">
                                            <IconButton color="info" onClick={() => setOpenUpdateRolesModal(true)}>
                                                <Add />
                                            </IconButton>
                                        </Tooltip>}
                                </Grid>
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 8}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Role</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.user.roles?.sort().map(role => {
                                            return <TableRow key={role}>
                                                <TableCell>{role}</TableCell>
                                                <TableCell align='right'>
                                                    {role === Role.NONE || !isAdmin
                                                        ? '----------'
                                                        :
                                                        <Tooltip title={`Remove ${role}`}>
                                                            <IconButton color="error"
                                                                onClick={() => {
                                                                    userApiClient.updateUserRoles(props.user.id, [role], UpdateAction.REMOVE)
                                                                        .then((user: User) => props.setUser(user))
                                                                }}
                                                            >
                                                                <Delete />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Password
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={() => setOpenPasswordChangeModal(true)} color="warning" variant="outlined">
                                    Change Password
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Delete User
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button disabled={userisAdmin} onClick={() => setOpenConfirmDeletion(true)} color="warning" variant="outlined">
                                    Delete User
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

            <PasswordChangeModal open={openPasswordChangeModal} onClose={() => setOpenPasswordChangeModal(false)} user={props.user} />
            <UpdateUserRolesModal open={openUpdateRolesModal} onClose={() => setOpenUpdateRolesModal(false)} user={props.user} setUser={props.setUser} />
            <ConfirmDialog open={openConfirmDeletion}
                onClose={() => setOpenConfirmDeletion(false)}
                confirmQuestion={'Do you really want to delete this user?'}
                confirmPhrase={props.user.username}
                onConfirm={() => userApiClient.deleteUser(props.user.id).then(() => navigate(isAdmin ? "/users" : "/"))}
                severity={'error'} />
        </>
    );
}

export default UserGeneral;