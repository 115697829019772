import ApiClient, { RequestOptions } from "./ApiClient";
import { createContext, useContext } from "react";
import { CustomerProjectRelation, Project } from "./models/Project";
import { ProjectPagination } from "./models/ProjectPagination";
import { UpdateAction } from "./models/UpdateAction";
import { useNotificationContext } from "../contexts/NotificationContext";
import { useUserContext } from "../contexts/UserContext";
import { Dayjs } from "dayjs";

export default class ProjectApiClient extends ApiClient {
    public getAllProjects = async (page?: number, pageSize?: number): Promise<ProjectPagination> => {
        return this.get(`/project/all?page=${page ?? 0}&pageSize=${pageSize ?? 20}`);
    }

    public getProject = async (id: string, options?: RequestOptions): Promise<Project> => {
        return this.get('/project/' + id, options);
    }

    public addProject = async (body: { name: string, description?: string, topics: { name: string, description?: string }[], permittedUserIds: string[] }): Promise<Project> => {
        return this.post('/project', { body });
    }

    public updateProject = async (id: string, body: { name?: string, description?: string }): Promise<Project> => {
        return this.put('/project/' + id, { body });
    }

    public searchProject = async (searchTerm: string, page?: number, pageSize?: number): Promise<ProjectPagination> => {
        return this.post(`/project/search?page=${page ?? 0}&pageSize=${pageSize ?? 20}`, { body: { searchTerm } });
    }

    public deleteProject = async (id: string) => {
        return this.delete('/project/' + id);
    }

    public updatePermittedUsers = async (id: string, body: { action: UpdateAction, userIds: string[] }): Promise<Project> => {
        return this.put(`/project/${id}/users`, { body })
    }

    public inviteCustomerToProject = async (projectId: string, userId: string): Promise<unknown> => {
        return this.get(`/project/${projectId}/customer/${userId}/invite`);
    }

    public removeCustomerFromProject = async (projectId: string, userId: string): Promise<unknown> => {
        return this.get(`/project/${projectId}/customer/${userId}/remove`);
    }

    public getAllPendingInvitations = async (): Promise<CustomerProjectRelation[]> => {
        return this.get(`/project/pending-invitations`);
    }

    public acceptInvitationForProject = async (projectId: string): Promise<unknown> => {
        return this.get(`/project/${projectId}/invitation/accept`);
    }

    public rejectInvitationForProject = async (projectId: string): Promise<unknown> => {
        return this.get(`/project/${projectId}/invitation/reject`);
    }

    public exportCsv = async (id: string, range: { start: Dayjs, end: Dayjs }): Promise<string> => {
        return this.post(`/project/${id}/export-csv`, { body: { start: range.start.format("YYYY-MM-DD"), end: range.end.format("YYYY-MM-DD") } })
    }
}


export const ProjectApiClientContext = createContext<ProjectApiClient>(new ProjectApiClient())

export const useProjectApiClient = () => {
    const context = useContext(ProjectApiClientContext)
    context.notificationCtx = useNotificationContext()
    context.userCtx = useUserContext()
    return context
}