import ApiClient from "./ApiClient";
import { createContext, useContext } from "react";
import { Dayjs } from "dayjs";
import { ProjectStats } from "./models/ProjectStats";
import { useNotificationContext } from "../contexts/NotificationContext";
import { useUserContext } from "../contexts/UserContext";
import { TopicTimeEntries } from "./models/Topic";

export default class AdminStatsClient extends ApiClient {

    // public getProjectStats = async (projectId: string, range?: { start: Dayjs, end: Dayjs }): Promise<SumPerDayUser[]> => {
    //     if (!range) {
    //         return this.get(`/project/${projectId}/stats`);
    //     }
    //     return this.post(`/project/${projectId}/stats/period`, { start: range.start.format('YYYY-MM-DD'), end: range.end.format('YYYY-MM-DD') });
    // } 
    public getProjectStats = async (projectId: string, range: { start: Dayjs, end: Dayjs }): Promise<ProjectStats> => {
        return this.post(`/project/${projectId}/stats/period`, { body: { start: range.start.format('YYYY-MM-DD'), end: range.end.format('YYYY-MM-DD') } });
    }
    public getTopicEntries = async (projectId: string, topicId: string, range: { start: Dayjs, end: Dayjs }): Promise<TopicTimeEntries> => {
        return this.post(`/project/${projectId}/stats/topic/${topicId}`, { body: { start: range.start.format('YYYY-MM-DD'), end: range.end.format('YYYY-MM-DD') } });
    }
}

export const AdminStatsClientContext = createContext<AdminStatsClient>(new AdminStatsClient())

export const useAdminStatsClient = () => {
    const context = useContext(AdminStatsClientContext)
    context.notificationCtx = useNotificationContext()
    context.userCtx = useUserContext()
    return context
}