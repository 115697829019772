import ApiClient from "./ApiClient";
import { createContext, useContext } from "react";
import { SumPerDayUser } from "./models/SumPerDayUser";
import { UserStats } from "./models/UserStats";
import { Dayjs } from "dayjs";
import { useNotificationContext } from "../contexts/NotificationContext";
import { useUserContext } from "../contexts/UserContext";
import { UserWeekStats } from "./models/UserWeekSta";

export default class UserStatsClient extends ApiClient {

    public getSumPerDay = async (userId: string, range: { start: Dayjs, end: Dayjs }): Promise<SumPerDayUser[]> => {
        return this.post('/user/' + userId + '/stats/sumPerDay', { body: { start: range.start.format("YYYY-MM-DD"), end: range.end.format("YYYY-MM-DD") } });
    }

    public getSummary = async (userId: string, range: { start: Dayjs, end: Dayjs }): Promise<UserStats> => {
        return this.post(`/user/${userId}/stats/summary`, { body: { start: range.start.format("YYYY-MM-DD"), end: range.end.format("YYYY-MM-DD") } })
    }

    public getSummaryWeek = async (userId: string, range: { start: Dayjs, end: Dayjs }): Promise<UserWeekStats> => {
        return this.post(`/user/${userId}/stats/weeks`, { body: { start: range.start.format("YYYY-MM-DD"), end: range.end.format("YYYY-MM-DD") } })
    }
}

export const UserStatsClientContext = createContext<UserStatsClient>(new UserStatsClient())

export const useUserStatsClient = () => {
    const context = useContext(UserStatsClientContext)
    context.notificationCtx = useNotificationContext()
    context.userCtx = useUserContext()
    return context
}