import { CircularProgress, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { CustomerProjectRelation } from '../../clients/models/Project';
import { useProjectApiClient } from '../../clients/ProjectClient';
import { Check, Close } from '@mui/icons-material';

export interface INotificationCenterProps {
}

const NotificationCenter: React.FunctionComponent<INotificationCenterProps> = (props: INotificationCenterProps) => {
    const [pendingInvitations, setPendingInvitations] = useState<CustomerProjectRelation[]>()

    const projectClient = useProjectApiClient()
    const pollNotifications = React.useRef<NodeJS.Timer>()

    const fetchNotifications = useCallback(() => {
        projectClient.getAllPendingInvitations()
            .then(cpr => setPendingInvitations(cpr))
    }, [projectClient])

    useEffect(() => {
        if (!pollNotifications.current) {
            pollNotifications.current = setInterval(() => fetchNotifications(), 5000)
        }
        return () => {
            if (pollNotifications.current) {
                clearInterval(pollNotifications.current)
                pollNotifications.current = undefined
            }
        }
    }, [fetchNotifications])



    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <Typography variant="h2">Notification Center</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Monitor all your pending invitations and notifications.</Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "3em" }}>
                    <Paper>
                        <Grid container sx={{ padding: "1em" }} >
                            <Grid item xs={12} container>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Pending Invitations</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Grid} item xs={12} >
                                        <Table sx={{ width: "100%" }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell >Project</TableCell>
                                                    <TableCell >Description</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {pendingInvitations ? pendingInvitations.map(cpr => {
                                                    if (!cpr.project) {
                                                        return <></>
                                                    }
                                                    return <TableRow key={cpr.project?.id + "_invitation"}>
                                                        <TableCell >
                                                            <Stack direction="row" spacing={2}>
                                                                <Tooltip title="Accept Invitation">
                                                                    <IconButton
                                                                        color="success"
                                                                        size='small'
                                                                        onClick={() => {
                                                                            projectClient.acceptInvitationForProject(cpr.project!.id)
                                                                                .then(() => projectClient.getAllPendingInvitations())
                                                                                .then(setPendingInvitations)
                                                                        }}
                                                                    >
                                                                        <Check />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Reject Invitation">
                                                                    <IconButton
                                                                        color="error"
                                                                        size='small'
                                                                        onClick={() => {
                                                                            projectClient.rejectInvitationForProject(cpr.project!.id)
                                                                                .then(() => projectClient.getAllPendingInvitations())
                                                                                .then(setPendingInvitations)
                                                                        }}
                                                                    >
                                                                        <Close />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell >{cpr.project?.name} </TableCell>
                                                        <TableCell >{cpr.project?.description}</TableCell>
                                                    </TableRow>
                                                }) : <>
                                                    <TableRow>
                                                        <TableCell colSpan={3} align="center" >
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow>
                                                </>}
                                                {pendingInvitations?.length === 0 &&
                                                    <TableRow>
                                                        <TableCell colSpan={3}>
                                                            <Typography align="center" variant="body1"><i>You have no pending invitations</i></Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default NotificationCenter;