import { createContext, useContext } from "react";
import ApiClient from "./ApiClient";
import { JwtToken } from "./models/JwtToken";
import { User } from "./models/User";
import { useNotificationContext } from "../contexts/NotificationContext";
import { useUserContext } from "../contexts/UserContext";

export default class AuthApiClient extends ApiClient {

    public signup = async (body: { username: string, password?: string, email: string }): Promise<User> => {
        return this.post('/auth/signup', { body });
    }

    public signupUser = async (body: { username: string, password?: string, email: string }): Promise<User> => {
        return this.post('/auth/signup/user', { body , preventAuthorization:true, preventTokenRefresh:true});
    }

    public signin = async (principal: string, password: string): Promise<JwtToken> => {
        return this.post('/auth/signin', { body: { principal, password }, preventTokenRefresh: true, preventAuthorization: true });
    }

    public refresh = async (refreshToken: string): Promise<JwtToken> => {
        return this.post('/auth/refreshtoken', { body: { refreshToken }, preventTokenRefresh: true, preventAuthorization: true, preventNotification: true });
    }

    public isAuthorized = async () => {
        return this.get('/auth/isauthorized', { preventNotification: true });
    }
}

export const AuthApiClientContext = createContext<AuthApiClient>(new AuthApiClient())

export const useAuthApiClient = () => {
    const context = useContext(AuthApiClientContext)
    context.notificationCtx = useNotificationContext()
    context.userCtx = useUserContext()
    return context
}