import { Role } from "../clients/models/Role";
import { User } from "../clients/models/User";

export const checkAccessforUser = (user?: User, roles?: Role[]) => {
    if (!user) {
        return false;
    }
    for (let i = 0; i < (roles?.length ?? 0); i++) {
        for (let j = 0; j < (user.roles?.length ?? 0); j++) {
            if (roles![i] === user.roles![j]) {
                return true;
            }
        }
    }
    return false;
}

export const sanitizeStringValue = (options: string[], defaultValue: string, value?: string) => {
    return options.find(o => o === value) ?? defaultValue
}