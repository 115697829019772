import { Add, Delete } from '@mui/icons-material';
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid'
import { Topic } from '../../../clients/models/Topic';

export interface ICreateProjectStepTwoProps {
    topics: Topic[]
    setTopics(topics: Topic[]): void
}

const CreateProjectStepTwo: React.FunctionComponent<ICreateProjectStepTwoProps> = (props: ICreateProjectStepTwoProps) => {
    const [newTopicName, setNewTopicName] = useState("")
    const [newTopicDesc, setNewTopicDesc] = useState("")

    return (
        <>

            <Grid container>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container sx={{ padding: "1em" }}>
                            <Grid item xs={12} container justifyContent="center" spacing={3}>
                                <Grid item xs={12}>
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                </TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {props.topics.map((topic) => {
                                                return <TableRow key={topic.id}>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            size='small'
                                                            color="error"
                                                            onClick={() => {
                                                                props.setTopics(props.topics.filter(t => t.id !== topic.id))
                                                            }}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell>{topic.name}</TableCell>
                                                    <TableCell>{topic.description ?? "---"}</TableCell>
                                                </TableRow>
                                            })}
                                            <TableRow>
                                                <TableCell align="right">
                                                    <IconButton
                                                        disabled={!newTopicName}
                                                        onClick={() => {
                                                            if (newTopicName) {
                                                                props.setTopics([...props.topics, {
                                                                    id: uuidv4(),
                                                                    name: newTopicName,
                                                                    description: newTopicDesc,
                                                                    budget: 0
                                                                }])
                                                                setNewTopicDesc("")
                                                                setNewTopicName("")
                                                            }
                                                        }}
                                                        title='Add topic'
                                                        size="small">
                                                        <Add />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        value={newTopicName}
                                                        onChange={e => setNewTopicName(e.target.value)}
                                                        size='small'
                                                        label="Name *" />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        value={newTopicDesc}
                                                        onChange={e => setNewTopicDesc(e.target.value)}
                                                        size='small'
                                                        label="Description" />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default CreateProjectStepTwo;