import dayjs from "dayjs"

export const getStartOfMonth = (date: dayjs.Dayjs): dayjs.Dayjs => {
    return date.set('date', 1)
}

export const getEndOfMonth = (date: dayjs.Dayjs): dayjs.Dayjs => {
    return date.set('date', date.daysInMonth())
}

export const getToday = () => {
    return dayjs()
}