import * as React from 'react';
import { Autocomplete, Badge, Fab, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Add, Delete } from '@mui/icons-material';
import { Project } from '../../clients/models/Project';
import { useTimeApiClient } from '../../clients/TimeClient';
import { Topic } from '../../clients/models/Topic';
import { TimeEntry } from '../../clients/models/TimeEntry';
import { DateCount } from '../../clients/models/DateCount';
import { useCheckMobileScreen } from '../../helpers/MobileHelper';

export interface IBookingProps { }
const Booking: React.FunctionComponent<IBookingProps> = (props: IBookingProps) => {
    const timeClient = useTimeApiClient()

    const [currentEntries, setCurrentEntries] = useState<TimeEntry[]>([])
    const [calendarLoading, setCalendarLoading] = useState(false)
    const [daysWithEntriesAvailable, setDaysWithEntriesAvailable] = useState<DateCount[]>()
    const [month, setMonth] = useState<Dayjs>(dayjs())
    const [date, setDate] = useState<Dayjs | null>(dayjs())
    const [availableProjects, setAvailableProjects] = useState<Project[]>()
    const [selectedProject, setSelectedProject] = useState<Project | null>(null)
    const [selectedTopic, setSelectedTopic] = useState<Topic | null>(null)
    const [hours, setHours] = useState("0.00")
    const [description, setDescription] = useState("")

    const [newEntryValid, setNewEntryValid] = useState(false)


    useEffect(() => {
        timeClient.getAvailableProjects()
            .then(res => {
                setAvailableProjects(res)
                setSelectedProject(res.length > 0 ? res[0] : null)
            })
    }, [timeClient])

    const getEntriesForDay = useCallback(() => {
        if (date) {
            timeClient.timeEntriesForDay(date)
                .then(setCurrentEntries)
        } else {
            setCurrentEntries([])
        }
    }, [date, timeClient])

    const getAvailableEntriesForMonth = useCallback(() => {
        setCalendarLoading(true)
        timeClient.entriesForMonthAvailable(month)
            .then(setDaysWithEntriesAvailable)
            .finally(() => setCalendarLoading(false))
    }, [month, timeClient])

    useEffect(() => {
        getAvailableEntriesForMonth()
    }, [getAvailableEntriesForMonth, month, timeClient])

    useEffect(() => {
        getEntriesForDay()
    }, [date, getEntriesForDay, timeClient])

    useEffect(() => {
        if (date && selectedProject && selectedTopic && hours && +hours > 0) {
            setNewEntryValid(true)
        } else {
            setNewEntryValid(false)
        }
    }, [date, hours, selectedProject, selectedTopic])

    const addEntry = () => {
        if (newEntryValid) {
            timeClient.addEntry(selectedProject!.id, selectedTopic!.id, { hours: +hours, description: description, date: date!.format("YYYY-MM-DD") })
                .then(() => {
                    getAvailableEntriesForMonth()
                    getEntriesForDay()
                })
                .then(() => {
                    setHours("0.00")
                    setSelectedProject(availableProjects && availableProjects.length > 0 ? availableProjects[0] : null)
                    setSelectedTopic(null)
                    setDescription("")
                })
        }
    }
    const Day = (props: PickersDayProps<Dayjs>) => {

        if (!daysWithEntriesAvailable) {
            return <PickersDay {...props} />
        }
        const dayCount = daysWithEntriesAvailable.find(dc => dayjs(dc.date).isSame(props.day) && dc.count > 0)
        return <Badge
            color="primary"
            key={props.day.toString()}
            overlap="circular"
            badgeContent={dayCount?.count}>
            <PickersDay {...props} sx={{ backgroundColor: (theme) => dayCount ? theme.palette.success.main + "80" : props.today ? theme.palette.primary.main + "40" : "" }} />
        </Badge>
    }
    const isMobile = useCheckMobileScreen()
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container sx={{ padding: isMobile ? "0" : "1em" }}>
                            <Grid item xs={12} container spacing={3} >
                                <Grid item xs={12} md={4}>
                                    <Paper>
                                        <Grid container sx={{ padding: isMobile ? "0.25em" : "1em" }} justifyContent="center">
                                            <Grid container item xs={12} spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5" align="center">Select date</Typography>
                                                </Grid>
                                                <Grid item xs={12} container>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateCalendar
                                                            sx={{ maxWidth: "100%", overflow: "auto" }}
                                                            onMonthChange={(m) => setMonth(m)}
                                                            loading={calendarLoading || !daysWithEntriesAvailable}
                                                            value={date}
                                                            onChange={(newDate) => setDate(newDate)}
                                                            displayWeekNumber={!isMobile}
                                                            slots={{ day: Day }}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={8} >
                                    <Paper>
                                        <Grid container sx={{ padding: "1em" }}>
                                            <Grid container item xs={12} spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5" align="center">Book time</Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{ borderTop: "1px" }} container spacing={2}>
                                                    <Grid item sx={{ display: { xs: "none", md: "inherit" } }} md={1}>
                                                        <IconButton
                                                            size='small'
                                                            color="success"
                                                            disabled={!newEntryValid}
                                                            onClick={addEntry}
                                                        >
                                                            <Add />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item sx={{ display: { md: "none", xs: "inherit" } }} container justifyContent="flex-end" xs={12}>
                                                        <Fab
                                                            size='small'
                                                            color="success"
                                                            disabled={!newEntryValid}
                                                            onClick={addEntry}
                                                        >
                                                            <Add />
                                                        </Fab>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <Autocomplete
                                                            fullWidth
                                                            size='small'
                                                            disablePortal
                                                            clearOnBlur
                                                            selectOnFocus
                                                            options={availableProjects ?? []}
                                                            getOptionLabel={(p) => p.name}
                                                            value={selectedProject}
                                                            onChange={(_e, p, _reason) => {
                                                                setSelectedProject(p)
                                                                if (!p) {
                                                                    setSelectedTopic(null)
                                                                }
                                                            }}
                                                            renderInput={(params) => <TextField {...params} label="Project *" />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <Autocomplete
                                                            fullWidth
                                                            size='small'
                                                            disabled={!selectedProject}
                                                            options={selectedProject?.topics ?? []}
                                                            getOptionLabel={(t) => t.name}
                                                            value={selectedTopic}
                                                            onChange={(_e, t, _reason) => setSelectedTopic(t)}
                                                            renderInput={(params) => <TextField {...params} label="Topic *" />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            size='small'
                                                            onFocus={(e) => e.target.select()}
                                                            inputProps={{ inputMode: 'decimal' }}
                                                            value={hours}
                                                            label="Time *"
                                                            fullWidth
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position='end'>h</InputAdornment>
                                                            }}
                                                            onChange={(e) => {
                                                                setHours(e.target.value
                                                                    .replace(/[^0-9.,]/g, "")
                                                                    .replace(/,/g, ".")
                                                                    .replace(/(\..*)\./g, '$1')
                                                                    .replace(/(\.\d{0,2}).*/g, "$1")) // this should be done with lookbehind regex, but iOs does not support this -_-
                                                            }}
                                                            type="text"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <TextField
                                                            fullWidth
                                                            size='small'
                                                            onFocus={(e) => e.target.select()}
                                                            label="Description"
                                                            value={description}
                                                            onChange={(e) => setDescription(e.target.value)} />
                                                    </Grid>
                                                </Grid>
                                                <TableContainer component={Grid} sx={{ overflow: "auto" }} item xs={12}>
                                                    <Table sx={{ width: "100%" }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell />
                                                                <TableCell>
                                                                    Project
                                                                </TableCell>
                                                                <TableCell>
                                                                    Topic
                                                                </TableCell>
                                                                <TableCell>
                                                                    Time
                                                                </TableCell>
                                                                <TableCell>
                                                                    Description
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {currentEntries.map(entry => {
                                                                return (<TableRow key={entry.id}>
                                                                    <TableCell>
                                                                        <IconButton
                                                                            color="error"
                                                                            onClick={() => {
                                                                                timeClient.deleteEntry(entry.id)
                                                                                    .then(() => {
                                                                                        getAvailableEntriesForMonth()
                                                                                        getEntriesForDay()
                                                                                    })
                                                                            }}
                                                                        >
                                                                            <Delete />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                    <TableCell sx={{
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis"
                                                                    }}>{entry.project}</TableCell>
                                                                    <TableCell sx={{
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis"
                                                                    }}>{entry.topic}</TableCell>
                                                                    <TableCell>{entry.hours.toFixed(2)}</TableCell>
                                                                    <TableCell sx={{
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis"
                                                                    }}>{entry.description ?? "---"}</TableCell>
                                                                </TableRow>)
                                                            })}
                                                            {currentEntries.length === 0 && <TableRow>
                                                                <TableCell colSpan={5}>
                                                                    <Typography variant="body2" align="center">
                                                                        <i>No bookings yet.</i>
                                                                    </Typography>
                                                                </TableCell>
                                                                </TableRow>}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid >
                    </Paper >
                </Grid >
            </Grid >
        </>
    );


}

export default Booking;