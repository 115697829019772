import { Chip, Grid, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAdminStatsClient } from '../../../clients/AdminStatsClient';
import { Project } from '../../../clients/models/Project';
import { ProjectStats } from '../../../clients/models/ProjectStats';
import CollapsableRow from '../../../components/common/CollapsableRow';
import TimeRangeSelector from '../../../components/times/TimeRangeSelector';
import { getStartOfMonth, getEndOfMonth } from '../../../helpers/TimeHelper';

export interface IProjectTimeStatsProps {
    project: Project
    setProject(p: Project): void
}

const ProjectTimeStats: React.FunctionComponent<IProjectTimeStatsProps> = (props: IProjectTimeStatsProps) => {
    const statsClient = useAdminStatsClient()

    const [start, setStart] = useState<Dayjs | null>(getStartOfMonth(dayjs()))
    const [end, setEnd] = useState<Dayjs | null>(getEndOfMonth(dayjs()))

    const [stats, setStats] = useState<ProjectStats>()
    const [statsLoading, setStatsLoading] = useState(false)
    useEffect(() => {
        setStatsLoading(true)
        statsClient.getProjectStats(props.project.id, { start: start ?? dayjs(), end: end ?? dayjs() })
            .then(setStats)
            .finally(() => setStatsLoading(false))
    }, [end, props.project.id, start, statsClient])

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TimeRangeSelector
                        start={start}
                        end={end}
                        setEnd={setEnd}
                        setStart={setStart}
                    />
                </Grid>
                {statsLoading || !stats ?
                    <Grid item xs={12} sx={{ marginTop: "0.75em" }} >
                        <Stack spacing={3}>
                            <Skeleton variant='rounded' />
                            <Skeleton variant='rounded' />
                            <Skeleton variant='rounded' />
                        </Stack>
                    </Grid> :
                    <Grid item xs={12} sx={{ marginTop: "0.75em", maxHeight: "20em", overflow: "auto" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Topic</TableCell>
                                    <TableCell>Total Hours  <Chip size='small' label={stats.topics.map(t => t.totalHours).reduce((a, b) => a + b, 0)} /></TableCell>
                                    <TableCell>Budget</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {stats.topics.map((topic) => {
                                    const TableCells = () => {
                                        return <>
                                            <TableCell>
                                                {topic.name}
                                            </TableCell>
                                            <TableCell>{topic.totalHours}</TableCell>
                                            <TableCell>{topic.budget && topic.budget !== 0 ? topic.budget : '----'}</TableCell>
                                        </>
                                    }

                                    if (topic.userHours) {
                                        return <CollapsableRow
                                            key={topic.id}
                                            numberOfCols={3}
                                            collapsedContent={<Box sx={{ margin: "0.75em" }}>
                                                <Table size='small'>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>User</TableCell>
                                                            <TableCell>Hours</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {topic.userHours.map(uh => <TableRow key={uh.userId}>
                                                            <TableCell><Link to={`/users/${uh.userId}`}> {uh.userName}</Link></TableCell>
                                                            <TableCell> {uh.totalHours}</TableCell>
                                                        </TableRow>)}
                                                    </TableBody>
                                                </Table>
                                            </Box>}
                                        >
                                            <TableCells />
                                        </CollapsableRow>
                                    }
                                    return <TableRow key={topic.id}>
                                        <TableCell />
                                        <TableCells />
                                    </TableRow>
                                }
                                )}
                                {stats.topics.length === 0 && <TableRow>
                                    <TableCell colSpan={4}>
                                        <Typography align="center" variant="body1"><i>There are no bookings for this time span.</i></Typography>
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </Grid>
                }
            </Grid>
        </>
    );
}

export default ProjectTimeStats;