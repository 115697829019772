import { AlertColor } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import { createContext, useContext } from "react"
import { v4 as uuidv4 } from "uuid"

export interface NotificationContext {
    notify(n: RequestNotification): void
}
export interface NotificationHolderContext {
    notifications: NotificationAlert[]
}

export interface RequestNotification {
    message: string
    severity: AlertColor
    timeout?: number
    onClose?(): void
}

export interface NotificationAlert extends RequestNotification {
    id: string,
    timer?: NodeJS.Timeout
}

const notificationHolderContext: NotificationHolderContext = {
    notifications: []
}
const notificationContext: NotificationContext = {
    notify: function (n: RequestNotification): void {
        throw new Error("Function not implemented.")
    }
}

export const NotificationHolderContextHolder = createContext<NotificationHolderContext>(notificationHolderContext)

export const useNotificationHolderContext = () => {
    return useContext(NotificationHolderContextHolder)
}
export const NotificationContextHolder = createContext<NotificationContext>(notificationContext)

export const useNotificationContext = () => {
    return useContext(NotificationContextHolder)
}


export interface INotificationContextProps {
}

const NotificationContextProvider: React.FunctionComponent<React.PropsWithChildren<INotificationContextProps>> = (props: React.PropsWithChildren<INotificationContextProps>) => {
    const [notifications, setNotifications] = useState<NotificationAlert[]>([])

    const notify = (n: RequestNotification) => {
        setNotifications(ns => [...ns, { ...n, id: uuidv4() }])
    }

    useEffect(() => {
        notifications.forEach((n) => {
            if (!n.timer) {
                const timer = setTimeout(() => {
                    setNotifications(ns => ns.filter(fn => fn.id !== n.id))
                    n.onClose && n.onClose()
                }, n.timeout ?? 10000)
                setNotifications(ns => ns.map(fn => fn.id === n.id ? { ...fn, timer: timer } : fn))
            }
        })
    }, [notifications])

    const notificationContext: NotificationContext = useMemo(() => { return { notify } }, [])
    const notificationHolderContext = useMemo(() => { return { notifications } }, [notifications])



    return (<>
        <NotificationContextHolder.Provider value={notificationContext}>
            <NotificationHolderContextHolder.Provider value={notificationHolderContext}>
                {props.children}
            </NotificationHolderContextHolder.Provider>
        </NotificationContextHolder.Provider>
    </>)
}

export default NotificationContextProvider;
