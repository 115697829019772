import { CircularProgress, Grid, IconButton, Paper, Skeleton, Stack, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import * as React from 'react';
import { UserPagination } from '../../clients/models/UserPagination';
import { useUserApiClient } from '../../clients/UserApiClient';
import { Add } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import { Link, useNavigate } from 'react-router-dom';
import { useCheckMobileScreen } from '../../helpers/MobileHelper';

export interface IUsersProps {
}

const Users: React.FunctionComponent<IUsersProps> = (props: IUsersProps) => {
    const userApiClient = useUserApiClient()

    const [searchTerm, setSearchTerm] = React.useState("")

    const [pageSize, setPageSize] = React.useState(10)
    const [page, setPage] = React.useState(0)
    const [result, setResult] = React.useState<UserPagination>()
    const [loading, setLoading] = React.useState(false)

    const isMobile = useCheckMobileScreen()

    const navigate = useNavigate()

    React.useEffect(() => {
        const fetchMethod = () => searchTerm ? userApiClient.searchUser(searchTerm, page, pageSize) : userApiClient.getAllUsers(page, pageSize)
        setLoading(true)
        fetchMethod()
            .then((res: UserPagination) => setResult(res))
            .finally(() => setLoading(false))
    }, [page, pageSize, searchTerm, userApiClient])

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container sx={{ padding: "0.5em" }} justifyContent="center">
                            <Grid item xs={12} container spacing={1}>
                                <Grid item xs={11}>
                                    <Typography variant="h5">Search Users</Typography>
                                </Grid>
                                <Grid item xs={1} container justifyContent="flex-end">
                                    {loading && <CircularProgress size={25} />}
                                </Grid>
                                <Grid item xs={10} md={11} container alignContent="center">
                                    <TextField value={searchTerm} onChange={e => {
                                        setSearchTerm(e.target.value)
                                    }} sx={{ width: "100%" }} size='small' label="Search users by mail or username" type="search" />
                                </Grid>
                                <Grid item xs={2} md={1}>
                                    <IconButton >
                                        <SearchIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper>
                        <Grid container sx={{ padding: "0.5em" }} justifyContent="center">
                            <Grid item xs={12} container spacing={1}>
                                <Grid item xs={11}>
                                    <Typography variant="h5">Users</Typography>
                                </Grid>
                                <Grid item xs={1} container justifyContent="flex-end">
                                    <Tooltip title="Add new user">
                                        <IconButton component={Link} to="/users/create" color="success" >
                                            <Add />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                {result ?
                                    <>
                                        <TableContainer component={Grid} item xs={12} >
                                            <Table sx={{ width: "100%" }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{ width: "50%" }} > Username</TableCell>
                                                        <TableCell sx={{ width: "50%" }} >Email</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        result.users.map((user) => {

                                                            return <TableRow key={user.id} hover sx={{ ':hover': { cursor: 'pointer' } }} onClick={() => navigate(`/users/${user.id}`)} >
                                                                <TableCell key={user.username} sx={{
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis"
                                                                }}>{user.username}</TableCell>
                                                                <TableCell key={user.email} sx={{
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis"
                                                                }}>{user.email}</TableCell>
                                                            </TableRow>
                                                        })
                                                    }
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[10, 15, 20]}
                                                            labelRowsPerPage={isMobile ? "Rows" : "Rows per Page"}
                                                            count={result.totalResults}
                                                            rowsPerPage={pageSize}
                                                            page={page}
                                                            onPageChange={(e, newPage) => {
                                                                setPage(newPage)
                                                            }}
                                                            onRowsPerPageChange={(e) => {
                                                                setPageSize(+e.target.value)
                                                            }}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer>
                                        <Grid item xs={12} container justifyContent="center">
                                        </Grid>
                                    </>
                                    :
                                    <Grid item xs={12}>
                                        <Stack>
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                        </Stack>
                                    </Grid>}

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid >
            </Grid >
        </>
    );
}

export default Users;