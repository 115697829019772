import { Box, setRef } from "@mui/material";
import React, { useMemo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useAuthApiClient } from "./clients/AuthApiClient";
import { User } from "./clients/models/User";
import { useUserApiClient } from "./clients/UserApiClient";
import Footer from "./components/common/Footer";
import GlobalLoader from "./components/common/GlobalLoader";
import Header from "./components/common/Header";
import Protected from "./components/routing/Protected";
import { useUserContext } from "./contexts/UserContext";
import { ManagedRoute, routes } from "./config/Routes";
import Page404 from "./components/routing/Page404";
import NotificationHandler from "./components/notification/NotificationHandler";

export default function Root() {
    const isMobile = React.useRef(window.innerWidth < 480);
    React.useEffect(() => {
        setRef(isMobile, window.innerWidth < 480)
    });

    const RouteContent = (props: { route: ManagedRoute }) => {
        return (<>
            <Header /> <br />
            <Box component="div" sx={{
                margin: "auto",
                marginBottom: "5em",
                marginTop: "2em",
                width: isMobile.current ? "85%" : "90%",
                maxWidth: "80em"
            }}>
                {props.route.protected ?
                    <Protected roles={props.route.roles}>
                        {props.route.child}
                    </Protected>
                    : <>{props.route.child}</>
                }
            </Box>
            <Footer />
        </>)
    }



    const userContext = useUserContext()

    const authApiClient = useAuthApiClient()
    const userApiClient = useUserApiClient()

    const [authorizing, setAuthorizing] = React.useState(true)

    React.useEffect(() => {
        if (!userContext.isLoggedIn()) {
            userContext.setTokenFromCookies()
            authApiClient.isAuthorized()
                .then(() => {
                    return userApiClient.getCurrentUser()
                        .then((user: User) => {
                            userContext.setCurrentUser(user)
                        })
                })
                .finally(() => setAuthorizing(false))
        } else {
            setAuthorizing(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Content = useMemo(() => {
        const displayedRoutes: JSX.Element[] = []
        routes.forEach(route => {
            displayedRoutes.push(<Route key={route.path} path={route.path} element={<RouteContent route={route} />} />)
        })

        displayedRoutes.push(<Route key="404" path="*" element={<RouteContent route={{
            child: < Page404 />,
            path: "*"
        }} />} />)

        if (authorizing) {
            return <GlobalLoader />
        }
        return (<Box sx={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            minHeight: "100vh"
        }} component="div">
            <BrowserRouter>
                <Routes>
                    {displayedRoutes}
                </Routes>
            </BrowserRouter>
        </Box>)

    }, [authorizing])

    return <>
        {Content}
        <NotificationHandler />
    </>
}