import * as React from 'react';
import { User } from '../../../clients/models/User';
import { Box, Chip, Grid, Paper, Skeleton, Stack, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useUserStatsClient } from '../../../clients/UserStatsClient';
import { UserStats } from '../../../clients/models/UserStats';
import TimeRangeSelector from '../../../components/times/TimeRangeSelector';
import CollapsableRow from '../../../components/common/CollapsableRow';
import { UserWeekStats } from '../../../clients/models/UserWeekSta';
import { getStartOfMonth, getEndOfMonth } from '../../../helpers/TimeHelper';

export interface IUserStatsProps {
    user: User
    setUser(u: User): void
}

const UserTimeStats: React.FunctionComponent<IUserStatsProps> = (props: IUserStatsProps) => {
    const statsClient = useUserStatsClient()

    const [start, setStart] = useState<Dayjs | null>(getStartOfMonth(dayjs()))
    const [end, setEnd] = useState<Dayjs | null>(getEndOfMonth(dayjs()))

    const [statsLoading, setStatsLoading] = useState(false)
    const [weekSumsLoading, setWeekSumsLoading] = useState(false)
    const [weekSums, setWeekSums] = React.useState<UserWeekStats>()
    const [stats, setStats] = useState<UserStats>()

    const [viewModeProject, setViewModeProject] = useState(true)

    useEffect(() => {
        setStatsLoading(true)
        statsClient.getSummary(props.user.id, { start: start ?? dayjs(), end: end ?? dayjs() })
            .then(setStats)
            .finally(() => setStatsLoading(false))

        setWeekSumsLoading(true)
        statsClient.getSummaryWeek(props.user.id, { start: start ?? dayjs(), end: end ?? dayjs() })
            .then(res => setWeekSums(res))
            .finally(() => setWeekSumsLoading(false))

    }, [end, props.user.id, start, statsClient])

    const ProjectView = () => {
        if (statsLoading || !stats) {
            return <Grid item xs={12} sx={{ marginTop: "0.75em" }} >
                <Stack spacing={3}>
                    <Skeleton variant='rounded' />
                    <Skeleton variant='rounded' />
                    <Skeleton variant='rounded' />
                </Stack>
            </Grid>
        }
        return <Grid item xs={12} sx={{ marginTop: "0.75em", maxHeight: "20em", overflow: "auto" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Project</TableCell>
                        <TableCell>Total Hours <Chip size='small' label={stats.projectUserStatsList.map(s => s.totalHours).reduce((a, b) => a + b, 0)} /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {stats.projectUserStatsList.map((project) => {
                        return <CollapsableRow
                            key={project.projectId}
                            numberOfCols={2}
                            collapsedContent={<Box sx={{ margin: "0.75em" }}>
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Topic</TableCell>
                                            <TableCell>Hours</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {project.topicUserStatsList.map(t => <TableRow key={t.topicId}>
                                            <TableCell>{t.topicName}</TableCell>
                                            <TableCell> {t.totalHours}</TableCell>
                                        </TableRow>)}
                                    </TableBody>
                                </Table>
                            </Box>}
                        >
                            <TableCell>
                                {project.projectName}
                            </TableCell>
                            <TableCell>{project.totalHours}</TableCell>
                        </CollapsableRow>
                    })}
                    {stats.projectUserStatsList.length === 0 && <TableRow>
                        <TableCell colSpan={3}>
                            <Typography align="center" variant="body1"><i>There are no bookings for this time span.</i></Typography>
                        </TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </Grid>

    }

    const WeeklyView = () => {
        if (weekSumsLoading || !weekSums) {
            return <Grid item xs={12} sx={{ marginTop: "0.75em" }} >
                <Stack spacing={3}>
                    <Skeleton variant='rounded' />
                    <Skeleton variant='rounded' />
                    <Skeleton variant='rounded' />
                </Stack>
            </Grid>
        }
        return <Grid item xs={12} sx={{ marginTop: "0.75em", maxHeight: "20em", overflow: "auto" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Week</TableCell>
                        <TableCell>Start</TableCell>
                        <TableCell>End</TableCell>
                        <TableCell>Total Hours</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {weekSums.timeUserStatsWeeks?.map((week) => {
                        return <CollapsableRow
                            key={week.weeknumber}
                            numberOfCols={4}
                            collapsedContent={<Box sx={{ margin: "0.75em" }}>
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Topic</TableCell>
                                            <TableCell>Desription</TableCell>
                                            <TableCell>Hours</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {week.entries.map(e => <TableRow key={e.topic + e.hours + e.description}>
                                            <TableCell>{e.date}</TableCell>
                                            <TableCell>{e.topic}</TableCell>
                                            <TableCell>{e.description}</TableCell>
                                            <TableCell>{e.hours}</TableCell>
                                        </TableRow>)
                                        }
                                    </TableBody>
                                </Table>
                            </Box>}
                        >
                            <TableCell>{week.weeknumber}</TableCell>
                            <TableCell>{week.start}</TableCell>
                            <TableCell>{week.end}</TableCell>
                            <TableCell>{week.totalHours}</TableCell>
                        </CollapsableRow>
                    })}
                    {weekSums.timeUserStatsWeeks.length === 0 && <TableRow>
                        <TableCell colSpan={3}>
                            <Typography align="center" variant="body1"><i>There are no bookings for this time span.</i></Typography>
                        </TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </Grid>

    }


    return (
        <>
            <Paper>
                <Grid container sx={{ padding: "1em" }} spacing={1}>
                    <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
                        <Grid item sx={{ marginRight: "1em" }}>
                            <Typography variant="h6">
                                Toggle view mode:
                            </Typography>
                        </Grid>
                        <Grid item><Typography variant="body1">Weekly View</Typography></Grid>
                        <Grid item><Switch checked={viewModeProject} onChange={(e, checked) => setViewModeProject(checked)} /></Grid>
                        <Grid item><Typography variant="body1">Project View</Typography></Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12}>
                            <TimeRangeSelector
                                start={start}
                                end={end}
                                setEnd={setEnd}
                                setStart={setStart}
                            />
                        </Grid>
                        {viewModeProject ? <ProjectView /> : <WeeklyView />}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default UserTimeStats;