import { SxProps, TextField } from "@mui/material";
import React from "react";


interface IFormInputProps {
    input: string;
    onInputChange(nextUser: string): void;
    onSubmit?(): void
    onBlur?(): void
    errortext?: string;
    error?: boolean;
    label: string;
    sx?: SxProps
}

const FormInput: React.FunctionComponent<IFormInputProps> = (props: IFormInputProps) => {

    return (
        <TextField
            id="outlined-input"
            label={props.label}
            value={props.input}
            onBlur={props.onBlur}
            onKeyDown={(e) => {
                if (e.key === 'Enter' && props.onSubmit) {
                    props.onSubmit()
                }
            }}
            onChange={(e) => props.onInputChange(e.target.value)}
            error={props.error}
            helperText={props.error ? props.errortext : ""}
            sx={props.sx}
        />);

}

export default FormInput