import Cookies from "universal-cookie";
import ConfigProvider from "../config/ConfigProvider";
import { UserContext } from "../contexts/UserContext";
import { NotificationContext } from "../contexts/NotificationContext";
import { ErrorResponse } from "./models/errors/ErrorResponse";

export interface RequestOptions {
    body?: unknown
    preventTokenRefresh?: boolean
    preventBodyStringify?: boolean
    preventAuthorization?: boolean
    preventNotification?: boolean
}

export default class ApiClient {
    protected BASE_URL: string;
    protected AUTHENTICATION_HEADER: boolean;

    private _userCtx: UserContext | undefined;
    private _notificationCtx: NotificationContext | undefined;

    constructor(ctx?: { nCtx?: NotificationContext, uCtx?: UserContext }) {
        this.BASE_URL = ConfigProvider.TimeMakerBaseUrl;
        this.AUTHENTICATION_HEADER = true;
        this.userCtx = ctx?.uCtx
        this.notificationCtx = ctx?.nCtx
    }

    protected request = async (method: string, url: string, options: RequestOptions) => {
        const cookies = new Cookies()

        const headers: HeadersInit = new Headers()
        !options.preventAuthorization && headers.append('Authorization', ('Bearer ' + (cookies.get("token") ?? '')));
        headers.set('Content-Type', 'application/json')

        const parseBody = (): string | undefined => {
            if (options.body) {
                if (options.preventBodyStringify) {
                    return options.body as string
                } else {
                    return JSON.stringify(options.body)
                }
            }
            return undefined
        }

        return fetch(this.BASE_URL + url, {
            method: method,
            headers: headers,
            body: parseBody()
        }).then((response: Response) => {
            if (response.status < 200 || response.status >= 300) {
                if (response.status === 401 && this.userCtx) {
                    this.userCtx.refreshToken()
                }
                throw response;
            }

            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json();
            } else {
                return response.text();
            }
        })
            .catch(async (error: Response | unknown) => {
                if (error instanceof Response) {
                    if (error.status === 401 && this.userCtx && !options.preventTokenRefresh) {
                        this.userCtx.refreshToken()
                    }
                    const body = await error.json() as ErrorResponse
                    if (this.notificationCtx && body.message && !options.preventNotification) {
                        this.notificationCtx.notify({ message: body.message, severity: 'error' })
                    }
                    throw body;
                }
                throw error
            });
    }
    protected get = async (url: string, options?: RequestOptions) => {
        return this.request('GET', url, options ?? {});
    }

    protected post = async (url: string, options?: RequestOptions) => {
        return this.request('POST', url, options ?? {});
    }

    protected put = async (url: string, options?: RequestOptions) => {
        return this.request('PUT', url, options ?? {});
    }

    protected patch = async (url: string, options?: RequestOptions) => {
        return this.request('PATCH', url, options ?? {});
    }

    protected delete = async (url: string, options?: RequestOptions) => {
        return this.request('DELETE', url, options ?? {});
    }



    public get notificationCtx(): NotificationContext | undefined {
        return this._notificationCtx;
    }
    public set notificationCtx(value: NotificationContext | undefined) {
        this._notificationCtx = value;
    }
    public get userCtx(): UserContext | undefined {
        return this._userCtx;
    }
    public set userCtx(value: UserContext | undefined) {
        this._userCtx = value;
    }
}