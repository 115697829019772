import { LoadingButton } from '@mui/lab';
import { Grid, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { useAuthApiClient } from '../../clients/AuthApiClient';
import { ErrorResponse } from '../../clients/models/errors/ErrorResponse';
import { JwtToken } from '../../clients/models/JwtToken';
import { User } from '../../clients/models/User';
import { useUserApiClient } from '../../clients/UserApiClient';
import FormInput from '../../components/common/FormInput';
import PasswordInput from '../../components/common/PasswordInput';
import { useUserContext } from '../../contexts/UserContext';

export interface ILoginProps {
}

const Login: React.FunctionComponent<ILoginProps> = (props: ILoginProps) => {
    const [principal, setPrincipal] = React.useState("")
    const [password, setPassword] = React.useState("")

    const userContext = useUserContext();

    const [loading, setLoading] = React.useState(false)

    const [params] = useSearchParams() // we want to check for query params, e.g [...]/login?redirect=/upload-wizzard
    const redirect = params.get("redirect") //get the redirect query param: "[...]/login?redirect=/upload-wizzard" would lead to redirect='/upload-wizzard'

    const authApiClient = useAuthApiClient()
    const userApiClient = useUserApiClient()

    const handleLogin = () => {
        setLoading(true)

        authApiClient.signin(principal, password).then(
            (jwtToken: JwtToken) => {
                userContext.login(jwtToken)
                userApiClient.getCurrentUser().then(
                    (user: User) => {
                        userContext.setCurrentUser(user)
                    }
                )
            }
        ).catch((response: ErrorResponse) => {
            // notificationContext.notify(response.message ?? "username/email or password are incorrect!", NotifySeverity.ERROR)
        })
            .finally(() => setLoading(false))
    };

    if (userContext.isLoggedIn()) {
        return <>
            <Navigate to={redirect ?? '/'} />
        </>
    }


    return (
        <>
            <Grid sx={{ paddingTop: "5em" }} container justifyContent="center">
                <Grid item xs={8}>
                    <Paper>
                        <Grid sx={{ padding: "1em" }} container justifyContent="center" spacing={3}>
                            <Grid item xs={12} container justifyContent="center">
                                <Typography variant="h4">Login</Typography>
                            </Grid>
                            <Grid item xs={12} container justifyContent="center">
                                <FormInput input={principal} onInputChange={setPrincipal} label="Mail or Username" sx={{ width: 300 }} />
                            </Grid>
                            <Grid item xs={12} container justifyContent="center">
                                <PasswordInput password={password} onPasswordChange={setPassword} sx={{ width: 300 }} />
                            </Grid>
                            <Grid item xs={12} container justifyContent="center">
                                <LoadingButton onClick={() => handleLogin()} loading={loading} variant="contained">Login</LoadingButton>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default Login;