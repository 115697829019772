import React from "react";
import { Role } from "../clients/models/Role";
import Booking from "../views/booking/Booking";
import Home from "../views/Home";
import Login from "../views/login/Login";
import CreateProject from "../views/projects/create/CreateProject";
import ProjectOverview from "../views/projects/project/ProjectOverview";
import TopicOverview from "../views/projects/project/topics/TopicOverview";
import Projects from "../views/projects/Projects";
import CreateUser from "../views/users/create/CreateUser";
import UserOverview from "../views/users/overview/UserOverview";
import Users from "../views/users/Users";
import NavigationCenter from "../views/NavigationCenter";
import Register from "../views/login/Register";
import NotificationCenter from "../views/notification/NotificationCenter";
export interface ManagedRoute {
    path: string
    name?: string,
    child: JSX.Element
    protected?: boolean
    roles?: Role[]
}


export const routes: (ManagedRoute)[] = [

    {
        path: "/",
        name: "Home",
        child: <Home />,
    },
    {
        path: "/goto",
        name: "Where do you want to go?",
        child: <NavigationCenter />,
        protected: true
    },
    {
        path: "/notifications",
        name: "Notification Center",
        child: <NotificationCenter />,
        protected: true
    },
    {
        path: "/booking",
        name: "Booking",
        child: <Booking />,
        protected: true
    },
    {
        path: "/projects",
        name: "Projects",
        child: <Projects />,
        protected: true,
        roles: [Role.ADMIN, Role.USER]
    },
    {
        path: "/projects/create",
        name: "Create Project",
        child: <CreateProject />,
        protected: true,
        roles: [Role.ADMIN]
    },
    {
        path: "/projects/:projectId/topics/:topicId/:tab",
        name: "View Project",
        child: <TopicOverview />,
        protected: true,
        roles: [Role.ADMIN, Role.USER]
    },
    {
        path: "/projects/:projectId/topics/:topicId",
        name: "View Project",
        child: <TopicOverview />,
        protected: true,
        roles: [Role.ADMIN, Role.USER]
    },
    {
        path: "/projects/:projectId",
        name: "View Project",
        child: <ProjectOverview />,
        protected: true,
        roles: [Role.ADMIN, Role.USER]
    },
    {
        path: "/projects/:projectId/:tab",
        name: "View Project",
        child: <ProjectOverview />,
        protected: true,
        roles: [Role.ADMIN, Role.USER]
    },
    {
        path: "/login",
        name: "Login",
        child: <Login />,
    },
    {
        path: "/register",
        name: "Register",
        child: <Register />,
    },
    {
        path: "/users",
        name: "Users",
        child: <Users />,
        protected: true,
        roles: [Role.ADMIN]
    },
    {
        path: "/users/create",
        name: "Create User",
        child: <CreateUser />,
        protected: true,
        roles: [Role.ADMIN]
    },
    {
        path: "/users/:userId",
        name: "User Overview",
        child: <UserOverview />,
        protected: true
    },
    {
        path: "/users/:userId/:tab",
        name: "User Overview",
        child: <UserOverview />,
        protected: true
    },
]
