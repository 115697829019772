import * as React from 'react';
import { Project } from '../../../clients/models/Project';
import { Autocomplete, CircularProgress, Fab, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { Visibility, Delete, Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useProjectApiClient } from '../../../clients/ProjectClient';
import { useUserApiClient } from '../../../clients/UserApiClient';
import { User } from '../../../clients/models/User';

export interface IProjectCustomersProps {
    project: Project
    setProject(p: Project): void
}

const ProjectCustomers: React.FunctionComponent<IProjectCustomersProps> = (props: IProjectCustomersProps) => {
    const userClient = useUserApiClient()
    const projectClient = useProjectApiClient()

    const navigate = useNavigate()

    const [submitAddUsersLoading, setSubmitAddUsersLoading] = React.useState(false)

    const [matchedUsers, setMatchedUsers] = React.useState<User[]>([])
    const [addUsers, setAddUsers] = React.useState<User[]>([])

    const onSearchChange = (term: string) => {
        if (term) {
            userClient.searchUser(term, 0, 20)
                .then(page => setMatchedUsers(page.users))
        } else {
            setMatchedUsers([])
        }
    }

    const submitCustomers = () => {
        if (addUsers.length > 0 && !submitAddUsersLoading) {
            setSubmitAddUsersLoading(true)
            Promise.all(addUsers.map((u) => projectClient.inviteCustomerToProject(props.project.id, u.id)))
                .then(() => setAddUsers([]))
                .finally(() => setSubmitAddUsersLoading(false))
                .then(() => projectClient.getProject(props.project.id))
                .then(p => props.setProject(p))
        }
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={11}>
                    <Autocomplete
                        size="small"
                        disablePortal
                        multiple
                        clearOnBlur
                        selectOnFocus
                        value={addUsers}
                        onBlur={() => onSearchChange("")}
                        onChange={(_e, users, _reason) => {
                            setAddUsers(users)
                            onSearchChange("")
                        }}
                        options={matchedUsers}
                        filterOptions={(options, _state) => options}
                        getOptionLabel={user => `${user.username}`}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <TextField
                            {...params}
                            label='Search to add users'
                            fullWidth
                            onChange={e => onSearchChange(e.target.value)}
                        />}
                    />
                </Grid>
                <Grid item xs={1} container justifyContent="centers">
                    <Grid item>
                        < Fab
                            size="small"
                            disabled={addUsers.length === 0 || submitAddUsersLoading}
                            onClick={submitCustomers}
                            color="success" >
                            {submitAddUsersLoading ? <CircularProgress /> : <Add />}
                        </Fab>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Customers</TableCell>
                                <TableCell>Invitation State</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {props.project.customers?.map((cpr) => {
                                if (!cpr.customer) {
                                    return <></>
                                }

                                return <TableRow hover key={cpr.customer.id + "_cpr"}>
                                    <TableCell align="left">
                                        <Tooltip title="See User">
                                            <IconButton
                                                color="info"
                                                size='small'
                                                onClick={() => {
                                                    navigate(`/users/${cpr.customer!.id}`)
                                                }}
                                            >
                                                <Visibility />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Remove Customer from Project">
                                            <IconButton
                                                color="error"
                                                size='small'
                                                onClick={() => {
                                                    projectClient.removeCustomerFromProject(props.project.id, cpr.customer!.id)
                                                        .then(() => projectClient.getProject(props.project.id))
                                                        .then(p => props.setProject(p))
                                                }}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell > {cpr.customer.username}</TableCell>
                                    <TableCell > {cpr.state}</TableCell>
                                </TableRow>
                            })}
                            {(props.project.customers?.length ?? 0) === 0 && <TableRow>
                                <TableCell colSpan={3}>
                                    <Typography align="center" variant="body1"><i>You have no customers on this project yet.</i></Typography>
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </>
    );
}

export default ProjectCustomers;