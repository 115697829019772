import ApiClient from "./ApiClient";
import { createContext, useContext } from "react";
import { Topic } from "./models/Topic";
import { useNotificationContext } from "../contexts/NotificationContext";
import { useUserContext } from "../contexts/UserContext";

export default class TopicApiClient extends ApiClient {
    public addTopic = async (projectId: string, body: { name: string, description: string, budget?: number }): Promise<Topic> => {
        return this.post(`/project/${projectId}/topic`, { body });
    }

    public updateTopic = async (projectId: string, id: string, body: { name?: string, description?: string, disabled?: boolean, budget?: number }): Promise<Topic> => {
        return this.put(`/project/${projectId}/topic/${id}`, { body });
    }

    public deleteTopic = async (projectId: string, id: string) => {
        return this.delete(`/project/${projectId}/topic/${id}`);
    }
}


export const TopicApiClientContext = createContext<TopicApiClient>(new TopicApiClient())

export const useTopicApiClient = () => {
    const context = useContext(TopicApiClientContext)
    context.notificationCtx = useNotificationContext()
    context.userCtx = useUserContext()
    return context
}