import { Cancel } from '@mui/icons-material';
import { Button, Grid, IconButton, Step, StepLabel, Stepper, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Topic } from '../../../clients/models/Topic';
import { User } from '../../../clients/models/User';
import { useProjectApiClient } from '../../../clients/ProjectClient';
import CreateProjectStepOne from './CreateProjectStepOne';
import CreateProjectStepThree from './CreateProjectStepThree';
import CreateProjectStepTwo from './CreateProjectStepTwo';

export interface ICreateProjectProps {
}

const CreateProject: React.FunctionComponent<ICreateProjectProps> = (props: ICreateProjectProps) => {
    const projectClient = useProjectApiClient()
    const navigate = useNavigate()

    const [step, setStep] = useState(0)

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")

    const [topics, setTopics] = useState<Topic[]>([])

    const [users, setUsers] = useState<User[]>([])

    const [stepsCompleted, setStepsCompleted] = useState([false, false, false])

    const finishWizzard = () => {
        if (stepsCompleted.every(c => c)) {
            projectClient.addProject({
                name: name,
                description: description,
                topics: topics.map(topic => { return { name: topic.name, description: topic.description } }),
                permittedUserIds: []
            })
                .then((project) => navigate(`/projects/${project.id}`))
        }
    }

    React.useEffect(() => {
        setStepsCompleted([
            !!(name && description),
            topics.length > 0,
            step === 2
        ])
    }, [description, name, step, topics.length])

    const Content = () => {
        switch (step) {
            case 0:
                return <CreateProjectStepOne
                    name={name}
                    setName={setName}
                    description={description}
                    setDescription={setDescription} />
            case 1:
                return <CreateProjectStepTwo
                    topics={topics}
                    setTopics={setTopics} />
            case 2:
                return <CreateProjectStepThree
                    users={users}
                    setUsers={setUsers} />
        }
        return <></>
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6} container>
                    <Grid item>
                        <IconButton title="Cancel" onClick={() => navigate("/projects")}>
                            <Cancel />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5">Create Project</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={6} container spacing={2} justifyContent={"right"}>
                    {step > 0 &&
                        <Grid item>
                            <Button size="small" variant="outlined" onClick={() => {
                                if (step > 0) {
                                    setStep(step - 1)
                                }
                            }} >
                                Back
                            </Button>
                        </Grid>
                    }
                    <Grid item>
                        <Button disabled={step === 2 && !stepsCompleted.every(stepCompleted => stepCompleted)} size="small" variant="contained" onClick={() => {
                            if (step === 2) {
                                finishWizzard()
                            } else {
                                setStep(step + 1)
                            }
                        }} >
                            {step === 2 ? "Create" : "Next"}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent={"center"}>
                    <Stepper sx={{ width: "100%" }} activeStep={step}>
                        <Step completed={stepsCompleted[0]} key="step-one">
                            <StepLabel error={!stepsCompleted[0] && step > 0}>
                                <Typography variant="body1">General information</Typography>
                                {!stepsCompleted[0] && step > 0 && <>
                                    <Typography variant="body2">Please add name and description</Typography>
                                </>}

                            </StepLabel>
                        </Step>
                        <Step completed={stepsCompleted[1]} key="step-two">
                            <StepLabel error={!stepsCompleted[1] && step > 1}>
                                <Typography variant="body1">Topics</Typography>
                                {!stepsCompleted[1] && step > 1 && <>
                                    <Typography variant="body2">You have to add at least one topic!</Typography>
                                </>}
                            </StepLabel>

                        </Step>
                        <Step completed={stepsCompleted[2]} key="step-three">
                            <StepLabel>Users</StepLabel>
                        </Step>
                    </Stepper>
                </Grid>
                <Grid item xs={12}>
                    <Content />
                </Grid>
            </Grid>
        </>
    );
}

export default CreateProject;