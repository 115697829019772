import ApiClient from "./ApiClient";
import { createContext, useContext } from "react";
import { TimeEntry } from "./models/TimeEntry";
import { Project } from "./models/Project";
import { Dayjs } from "dayjs";
import { DateCount } from "./models/DateCount";
import { useNotificationContext } from "../contexts/NotificationContext";
import { useUserContext } from "../contexts/UserContext";

export default class TimeApiClient extends ApiClient {
    public addEntry = async (projectId: string, topicId: string, body: { hours: number, description?: string, date: string }): Promise<TimeEntry> => {
        return this.post(`/project/${projectId}/topic/${topicId}/time-entry`, { body });
    }
    public getAvailableProjects = async (): Promise<Project[]> => {
        return this.get(`/project/available-projects`);
    }

    public deleteEntry = async (entryId: string) => {
        return this.delete(`/project/topic/time-entry/${entryId}`);
    }

    public timeEntriesForDay = async (day: Dayjs): Promise<TimeEntry[]> => {
        return this.post(`/time/for-day`, { body: { day: day.format("YYYY-MM-DD") } })
    }
    public entriesForMonthAvailable = async (month: Dayjs): Promise<DateCount[]> => {
        return this.post(`/time/entries-available`, { body: { month: month.format("YYYY-MM-DD") } })
    }
}


export const TimeApiClientContext = createContext<TimeApiClient>(new TimeApiClient())

export const useTimeApiClient = () => {
    const context = useContext(TimeApiClientContext)
    context.notificationCtx = useNotificationContext()
    context.userCtx = useUserContext()
    return context
}