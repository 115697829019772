import { Box, Dialog, DialogContent, Fab, Grid, TextField, Tooltip } from '@mui/material';
import * as React from 'react';
import DialogHeader from '../../../components/common/DialogHeader';
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import TimeRangeSelector from '../../../components/times/TimeRangeSelector';
import { useProjectApiClient } from '../../../clients/ProjectClient';
import { Project } from '../../../clients/models/Project';
import { LoadingButton } from '@mui/lab';
import { CopyAll } from '@mui/icons-material';
import { useNotificationContext } from '../../../contexts/NotificationContext';
import { getStartOfMonth, getEndOfMonth } from '../../../helpers/TimeHelper';

export interface IExportProjectModalProps {
    project: Project
    open: boolean
    onClose?(): void
}

const ExportProjectModal: React.FunctionComponent<IExportProjectModalProps> = (props: IExportProjectModalProps) => {
    const projectClient = useProjectApiClient()
    const notificationCtx = useNotificationContext()

    const [start, setStart] = useState<Dayjs | null>(getStartOfMonth(dayjs()))
    const [end, setEnd] = useState<Dayjs | null>(getEndOfMonth(dayjs()))

    const [loading, setLoading] = useState(false)
    const [csvText, setCsvText] = useState<string>()

    const fetchCsv = () => {
        setLoading(true)
        projectClient.exportCsv(props.project.id, { start: start ?? dayjs(), end: end ?? dayjs() })
            .then(res => {
                console.log(res)
                setCsvText(res)
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Dialog fullWidth maxWidth="lg" open={props.open} onClose={props.onClose}>
                <DialogHeader headline="Export Time bookings" closeDialog={props.onClose} />
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TimeRangeSelector
                                start={start}
                                end={end}
                                setEnd={setEnd}
                                setStart={setStart}
                            />
                        </Grid>
                        <Grid item xs={12} container justifyContent="center">
                            <Grid item>
                                <LoadingButton onClick={fetchCsv} variant='contained' loading={loading}>
                                    Request CSV
                                </LoadingButton>
                            </Grid>
                        </Grid>
                        {csvText && <>
                            <Grid item xs={12}>
                                <Box component="span" sx={{
                                    position: "relative"
                                }} >
                                    <TextField
                                        value={csvText}
                                        contentEditable={false}
                                        multiline sx={{
                                            width: "100%"
                                        }}
                                    >

                                    </TextField>
                                    <Tooltip title="Copy CSV Content">
                                        <Fab
                                            onClick={() => {
                                                navigator.clipboard.writeText(csvText)
                                                notificationCtx.notify({ message: "Copied CSV Content to clipboard", severity: "success" })
                                            }}
                                            sx={{
                                                position: "absolute",
                                                top: "0.5em",
                                                right: "0.5em"
                                            }}>
                                            <CopyAll />
                                        </Fab>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </>}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ExportProjectModal;