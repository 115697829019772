import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useAuthApiClient } from '../../../clients/AuthApiClient';
import { User } from '../../../clients/models/User';
import { useUserApiClient } from '../../../clients/UserApiClient';
import DialogHeader from '../../../components/common/DialogHeader';
import PasswordInput from '../../../components/common/PasswordInput';
import { useUserContext } from '../../../contexts/UserContext';

export interface IPasswordChangeModalProps {
    open: boolean
    user: User
    onClose(): void
    message?: string
}

const PasswordChangeModal: React.FunctionComponent<IPasswordChangeModalProps> = (props: IPasswordChangeModalProps) => {

    const userContext = useUserContext()

    const isCurrentUser = userContext.user?.id === props.user.id

    const [currentPassword, setCurrentPassword] = React.useState("")
    const [newPassword, setNewPassword] = React.useState("")
    const [repeatNewPassword, setRepeatNewPassword] = React.useState("")
    const [passwordCorrect, setPasswordCorrect] = React.useState(!isCurrentUser)
    const [newPasswordsMatch, setNewPasswordsMatch] = React.useState(false)
    const [currentPasswordErrorMessage, setCurrentPasswordErrorMessage] = React.useState<string | undefined>()
    const [newPasswordErrorMessage, setNewPasswordErrorMessage] = React.useState<string | undefined>()

    const [loading, setLoading] = React.useState(false)

    const authApiClient = useAuthApiClient()
    const userApiClient = useUserApiClient()


    const compareNewPasswords = () => {
        if (newPassword === repeatNewPassword) {
            setNewPasswordErrorMessage(undefined)
            setNewPasswordsMatch(true)
        } else {
            setNewPasswordErrorMessage("the new passwords do not match")
            setNewPasswordsMatch(false)
        }
        if (newPassword.length < 6) {
            setNewPasswordErrorMessage("password must be at least 6 characters long")
        } else {
            setNewPasswordErrorMessage(undefined)
        }
    }

    const onClose = () => {
        props.onClose()
        setNewPassword("")
        setNewPasswordErrorMessage(undefined)
        setCurrentPassword("")
        setRepeatNewPassword("")
        setPasswordCorrect(!isCurrentUser)
        setNewPasswordsMatch(false)
        setLoading(false)
        setCurrentPasswordErrorMessage(undefined)
    }

    return (
        <>
            <Dialog
                open={props.open}
                onClose={onClose}
                scroll="paper"
            >
                <DialogHeader
                    closeDialog={() => onClose()}
                    headline="Change Password"
                />
                <DialogContent dividers>
                    <Grid sx={{ padding: "2em", paddingTop: "1em", overflow: "auto" }} container spacing={3}>
                        {props.message &&
                            <Grid item xs={12}>
                                <Typography variant="body1"><i>{props.message}</i></Typography>
                            </Grid>
                        }
                        {isCurrentUser && <>
                            <Grid item xs={12}>
                                <Typography variant="body1"><b>Current Password</b></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <PasswordInput
                                    title="Current Password"
                                    password={currentPassword}
                                    onPasswordChange={setCurrentPassword}
                                    error={currentPasswordErrorMessage !== undefined}
                                    errortext={currentPasswordErrorMessage}
                                    onBlur={() => {
                                        authApiClient.signin(props.user.username, currentPassword)
                                            .then(() => {
                                                setPasswordCorrect(true)
                                                setCurrentPasswordErrorMessage(undefined)
                                            })
                                            .catch(() => {
                                                setPasswordCorrect(false)
                                                setCurrentPasswordErrorMessage("Password not correct.")
                                            })
                                    }} />
                            </Grid>
                        </>
                        }
                        <Grid item xs={12}>
                            <Typography variant="body1"><b>New Password</b></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordInput
                                title="New Password"
                                password={newPassword}
                                disabled={!passwordCorrect}
                                onPasswordChange={setNewPassword}
                                onBlur={compareNewPasswords} />
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordInput
                                title="Repeat New Password"
                                password={repeatNewPassword}
                                disabled={!passwordCorrect}
                                onPasswordChange={setRepeatNewPassword}
                                error={newPasswordErrorMessage !== undefined}
                                errortext={newPasswordErrorMessage}
                                onBlur={compareNewPasswords} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        disabled={!passwordCorrect || !newPasswordsMatch || newPassword.length < 6} onClick={() => {
                            setLoading(true)
                            if (isCurrentUser) {
                                userApiClient.updateCurrentUser({ password: newPassword })
                                    .then(() => onClose())
                                    .finally(() => setLoading(false))
                            } else {
                                userApiClient.updateUser(props.user.id, { password: newPassword })
                                    .then(() => onClose())
                                    .finally(() => setLoading(false))
                            }
                        }} variant="outlined">
                        Change Password
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default PasswordChangeModal;