import { Box, Grid } from '@mui/material';
import * as React from 'react';
import { Role } from '../clients/models/Role';
import Tile from '../components/navigation/Tile';
import TileField from '../components/navigation/TileField';
import { useUserContext } from '../contexts/UserContext';
import { useCheckMobileScreen } from '../helpers/MobileHelper';

export interface INavigationCenterProps {
}

const NavigationCenter: React.FunctionComponent<INavigationCenterProps> = (props: INavigationCenterProps) => {
    const isMobile = useCheckMobileScreen()

    const userCtx = useUserContext()

    const canManage = userCtx.userHasAccess(Role.ADMIN)
    const canBook = userCtx.userHasAccess(Role.ADMIN, Role.USER)

    const tiles = []
    if (canBook) {
        tiles.push(<Tile key="/booking" padImage imageUrl="svg/time-add.svg" title='Time Booking' to="/booking" description="" />)
        tiles.push(<Tile key="/projects" padImage imageUrl="svg/diagram-project.svg" title='Manage Projects' to="/projects" description="" />)
    }
    if (canManage) {
        tiles.push(<Tile key="/users" padImage imageUrl="svg/users.svg" title='Manage Users' to="/users" description="" />)
    }

    return (
        <>
            <Grid container justifyContent="center" rowSpacing={3}>
                <Grid item xs={12} container justifyContent="center" alignContent={"center"} >
                    <Box component="img" sx={{ height: isMobile ? "10em" : "7.5em" }} src="svg/icon_no_background.svg" />
                </Grid>
                <Grid item xs={12}><br /></Grid>
                <Grid item xs={isMobile ? 12 : 8}>
                    <TileField tilesPerRow={2}>
                        {tiles}
                    </TileField>
                </Grid>
            </Grid>
        </>
    );
}

export default NavigationCenter;