import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAuthApiClient } from '../../clients/AuthApiClient';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PasswordInput from '../../components/common/PasswordInput';
import { JwtToken } from '../../clients/models/JwtToken';
import { useUserContext } from '../../contexts/UserContext';

export interface IRegisterProps {
}

const Register: React.FunctionComponent<IRegisterProps> = (props: IRegisterProps) => {
    const [userName, setUserName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [repeatPassword, setRepeatPassword] = useState("")
    const [userCreatable, setUserCreatable] = useState(false)

    const [userNameError, setUserNameError] = useState("")
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")

    const [loading, setLoading] = useState(false)

    const authApiClient = useAuthApiClient()
    const userCtx = useUserContext()

    const navigate = useNavigate()

    useEffect(() => {
        const nameLength = userName.length > 3
        const validMail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
        const passwordLength = password.length > 5
        const repeatPasswordEqual = password === repeatPassword
        setUserCreatable(nameLength && validMail && passwordLength && repeatPasswordEqual)
        setUserNameError(userName ? nameLength ? '' : 'Name must be at least 4 characters long' : '')
        setEmailError(email ? validMail ? '' : 'Not a valid Email' : '')
        setPasswordError(password ? passwordLength ? repeatPasswordEqual ? '' : 'Repeat Password is not the same' : 'Password must be at least 6 characters long' : '')

    }, [userName, email, password, repeatPassword])

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center">
                        Register at Times!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container sx={{ padding: "1em" }}>
                            <Grid item xs={12} spacing={1} container>
                                <Grid item xs={12}>
                                    <Typography variant="h6" align="center">
                                        Enter your data
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} container justifyContent="center">
                                    <TextField
                                        sx={{ width: "100%", maxWidth: "30em" }}
                                        helperText={userNameError}
                                        error={!!userNameError}
                                        value={userName}
                                        onChange={e => setUserName(e.target.value)}
                                        label="Name"
                                        size="small" />
                                </Grid>
                                <Grid item xs={12} container justifyContent="center">
                                    <TextField
                                        sx={{ width: "100%", maxWidth: "30em" }}
                                        helperText={emailError}
                                        error={!!emailError}
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        label="Email"
                                        size="small" />
                                </Grid>
                                <Grid item xs={12} container justifyContent="center">
                                    <PasswordInput
                                        sx={{ width: "100%", maxWidth: "30em" }}
                                        errortext={passwordError}
                                        error={!!passwordError}
                                        password={password}
                                        onPasswordChange={pass => setPassword(pass)}
                                        title="Password"
                                        size="small" />
                                </Grid>
                                <Grid item xs={12} container justifyContent="center">
                                    <PasswordInput
                                        sx={{ width: "100%", maxWidth: "30em" }}
                                        password={repeatPassword}
                                        onPasswordChange={pass => setRepeatPassword(pass)}
                                        title="Repeat Password"
                                        size="small" />
                                </Grid>
                                <Grid item xs={12} container justifyContent="center">
                                    <LoadingButton variant="contained" onClick={() => {
                                        setLoading(true)
                                        authApiClient.signupUser({
                                            username: userName,
                                            email: email,
                                            password: password
                                        })
                                            .then((user) => authApiClient.signin(userName, password)
                                                .then((jwtToken: JwtToken) => {
                                                    userCtx.login(jwtToken)
                                                    userCtx.setCurrentUser(user)
                                                    navigate("/goto")
                                                }))
                                            .finally(() => setLoading(false))
                                    }} loading={loading} disabled={!userCreatable}>Register</LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>)
}

export default Register;