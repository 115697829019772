import ApiClient from "./ApiClient";
import { Role } from "./models/Role";
import { createContext, useContext } from "react";
import { User } from "./models/User";
import { UserPagination } from "./models/UserPagination";
import { UpdateAction } from "./models/UpdateAction";
import { useNotificationContext } from "../contexts/NotificationContext";
import { useUserContext } from "../contexts/UserContext";

export default class UserApiClient extends ApiClient {
    public getAllUsers = async (page: number, pageSize?: number): Promise<UserPagination> => {
        return this.get(`/user/all?page=${page}&pageSize=${pageSize ?? 20}`);
    }

    public getUser = async (username: string): Promise<User> => {
        return this.get('/user/' + username);
    }

    public getCurrentUser = async (): Promise<User> => {
        return this.get('/user/me');
    }

    public updateCurrentUser = async (body: { email?: string, username?: string, password?: string }): Promise<User> => {
        return this.put('/user/me', { body });
    }

    public updateUser = async (username: string, body: { email?: string, username?: string, password?: string }): Promise<User> => {
        return this.put('/user/' + username, { body });
    }

    public updateUserRoles = async (username: string, roles: Role[], action: UpdateAction): Promise<User> => {
        return this.put('/user/' + username + '/roles', { body: { action: action, roles: roles } });
    }


    public searchUser = async (searchTerm: string, page: number, pageSize?: number): Promise<UserPagination> => {
        return this.post(`/user/search/freetext?page=${page}&pageSize=${pageSize ?? 20}`, { body: { searchTerm } });
    }

    public deleteUser = async (username: string) => {
        return this.delete('/user/' + username);
    }
    public deleteCurrentUser = async () => {
        return this.delete('/user/me');
    }
}


export const UserApiClientContext = createContext<UserApiClient>(new UserApiClient())

export const useUserApiClient = () => {
    const context = useContext(UserApiClientContext)
    context.notificationCtx = useNotificationContext()
    context.userCtx = useUserContext()
    return context
}